import React from 'react';





const DonatePage = () => {
    return (
      <div>
        <div className="relative pt-16 pb-32 flex content-center items-center justify-center" style={{ minHeight: "75vh" }}>
          <div className="absolute top-0 w-full h-full bg-center bg-cover" style={{ backgroundImage: "url('https://plus.unsplash.com/premium_photo-1663050941092-e6b092d91b7c?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mjl8fGdyZWVuJTIwdHJlZXMlMjBwZW9wbGV8ZW58MHx8MHx8fDA%3D')" }}>
            <span id="blackOverlay" className="w-full h-full absolute opacity-75 bg-black"></span>
          </div>
          <div className="container relative mx-auto">
            <div className="items-center flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
                <div className="pr-12">
                  <h1 className="text-white font-semibold text-5xl">Join Us in Making a Difference</h1>
                  <p className="mt-4 text-lg text-gray-300">Your donations help us manage carbon emissions effectively, offering benefits like certification and carbon credits. Together, we can create a sustainable future.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden" style={{ height: "70px" }}>
            <svg className="absolute bottom-0 overflow-hidden" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" version="1.1" viewBox="0 0 2560 100" x="0" y="0">
              <polygon className="text-lime-900 fill-current" points="2560 0 2560 100 0 100"></polygon>
            </svg>
          </div>
        </div>
  
        <section className="pb-20 bg-lime-300 -mt-24">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap">
              <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-lime-800">
                      <i className="fas fa-certificate"></i>
                    </div>
                    <h6 className="text-xl font-semibold">Certification</h6>
                    <p className="mt-2 mb-4 text-gray-600">Receive official certification for your contributions to carbon management, enhancing your commitment to sustainability.</p>
                  </div>
                </div>
              </div>
  
              <div className="w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-lime-900">
                      <i className="fas fa-leaf"></i>
                    </div>
                    <h6 className="text-xl font-semibold">Carbon Credits</h6>
                    <p className="mt-2 mb-4 text-gray-600">Earn carbon credits for your donations, allowing you to offset your carbon footprint and contribute to global carbon reduction efforts.</p>
                  </div>
                </div>
              </div>
  
              <div className="pt-6 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-lime-950">
                      <i className="fas fa-globe"></i>
                    </div>
                    <h6 className="text-xl font-semibold">Global Impact</h6>
                    <p className="mt-2 mb-4 text-gray-600">Join a global movement dedicated to combating climate change and promoting sustainable practices worldwide.</p>
                  </div>
                </div>
              </div>
            </div>
  
            <div className="flex flex-wrap items-center mt-32">
              <div className="w-full md:w-5/12 px-4 mr-auto ml-auto">
                <div className="text-gray-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-gray-100">
                  <i className="fas fa-hands-helping text-xl"></i>
                </div>
                <h3 className="text-3xl mb-2 font-semibold leading-normal">Why Donate?</h3>
                <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">Your donations support vital projects that reduce carbon emissions and promote environmental sustainability. Each contribution makes a significant impact.</p>
                <p className="text-lg font-light leading-relaxed mt-0 mb-4 text-gray-700">By donating, you also receive recognition for your efforts through certifications and carbon credits, demonstrating your commitment to a greener planet.</p>
                <a href="/learn-more" className="font-bold text-gray-800 mt-8">Learn More</a>
              </div>
  
              <div className="w-full md:w-4/12 px-4 mr-auto ml-auto">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-lime-600">
                  <img alt="..." src="https://plus.unsplash.com/premium_photo-1664297758117-a1a6c8bec232?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MzN8fGdyZWVuJTIwdHJlZXMlMjBwZW9wbGV8ZW58MHx8MHx8fDA%3D" className="w-full align-middle rounded-t-lg" />
                  <blockquote className="relative p-8 mb-4">
                    <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 583 95" className="absolute left-0 w-full block" style={{ height: "95px", top: "-94px" }}>
                      <polygon points="-30,95 583,95 583,65" className="text-lime-600 fill-current"></polygon>
                    </svg>
                    <h4 className="text-xl font-bold text-white">Our Commitment</h4>
                    <p className="text-md font-light mt-2 text-white">We are dedicated to transparency and efficiency in our carbon management efforts, ensuring that every donation is used to its fullest potential for the environment.</p>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>
        </section>
  
        <section className="relative py-20">
          <div className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20" style={{ height: "80px" }}>
            <svg className="absolute bottom-0 overflow-hidden" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" version="1.1" viewBox="0 0 2560 100" x="0" y="0">
              <polygon className="text-white fill-current" points="2560 0 2560 100 0 100"></polygon>
            </svg>
          </div>
  
          <div className="container mx-auto px-4">
            <div className="items-center flex flex-wrap">
              <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
                <img alt="..." className="max-w-full rounded-lg shadow-lg" src="https://images.unsplash.com/photo-1597577856843-5573938f455d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTA2fHxncmVlbiUyMHRyZWVzJTIwcGVvcGxlfGVufDB8fDB8fHww" />
              </div>
              <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
                <div className="md:pr-12">
                  <div className="text-gray-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-gray-100">
                    <i className="fas fa-recycle text-xl"></i>
                  </div>
                  <h3 className="text-3xl font-semibold">Make a Lasting Impact</h3>
                  <p className="mt-4 text-lg leading-relaxed text-gray-600">By donating to our carbon management initiatives, you play a crucial role in mitigating climate change and fostering a sustainable future for all. Your support drives real, measurable change.</p>
                  <ul className="list-none mt-6">
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-white bg-lime-500 mr-3"><i className="fas fa-seedling"></i></span>
                        </div>
                        <div>
                          <h4 className="text-gray-600">Support reforestation and conservation projects</h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-white bg-lime-500 mr-3"><i className="fas fa-solar-panel"></i></span>
                        </div>
                        <div>
                          <h4 className="text-gray-600">Promote renewable energy solutions</h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-white bg-lime-500 mr-3"><i className="fas fa-hands-helping"></i></span>
                        </div>
                        <div>
                          <h4 className="text-gray-600">Empower communities to adopt sustainable practices</h4>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
  
        <section className="pb-20 relative block bg-gray-900">
          <div className="container mx-auto px-4 lg:pt-24 lg:pb-64">
            <div className="flex flex-wrap text-center justify-center">
              <div className="w-full lg:w-6/12 px-4">
                <h2 className="text-4xl font-semibold text-white">Get Involved</h2>
                <p className="text-lg leading-relaxed mt-4 mb-4 text-gray-500">Ready to make a difference? Choose a donation amount that suits you and become a part of the solution. Every contribution counts towards a healthier planet.</p>
              </div>
            </div>
            <div className="flex flex-wrap mt-12 justify-center">
              <div className="w-full lg:w-3/12 px-4 text-center">
                <div className="text-gray-900 bg-white p-8 shadow-lg rounded-lg">
                  <h5 className="text-xl font-semibold">Donate Rs500</h5>
                  <p className="mt-2 mb-4 text-gray-600">Support small-scale projects that make a big impact on local communities.</p>
                  <a href="https://rzp.io/l/giverly" className="bg-lime-500 text-white active:bg-lime-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 inline-block">Donate Now</a>
                </div>
              </div>
              <div className="w-full lg:w-3/12 px-4 text-center">
                <div className="text-gray-900 bg-white p-8 shadow-lg rounded-lg">
                  <h5 className="text-xl font-semibold">Donate Rs1000</h5>
                  <p className="mt-2 mb-4 text-gray-600">Contribute to larger projects that drive significant carbon reduction.</p>
                  <a href="/donate" className="bg-lime-500 text-white active:bg-lime-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 inline-block">Donate Now</a>
                </div>
              </div>
              <div className="w-full lg:w-3/12 px-4 text-center">
                <div className="text-gray-900 bg-white p-8 shadow-lg rounded-lg">
                  <h5 className="text-xl font-semibold">Donate Rs2000</h5>
                  <p className="mt-2 mb-4 text-gray-600">Make a substantial impact with major contributions to transformative projects.</p>
                  <a href="https://rzp.io/l/giverly" className="bg-lime-500 text-white active:bg-lime-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 inline-block">Donate Now</a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  };
  
  export default DonatePage;
  