import React from 'react';
import { data, images } from '../constants';
import '../pages/styles.css'

import Carousel from 'react-bootstrap/Carousel';

import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import { useTranslation } from 'react-i18next';


const Customer1 = () => {
  const { t } = useTranslation(); 
  return (
    <div className="banner-pg">
    <div className="banner-pic">
      
      
      <div className="bannertitle">
        <h1>Carbon Solutions for Corporations</h1>
      </div>
    </div>
      {/* First Section - Image on Left */}
      <div className="customer-container">
        <div className="image-container">
          {/* Insert your image here */}
          {/* Example: <img src="your-image-url.jpg" alt="Your Image" /> */}
          <img src='https://images.unsplash.com/photo-1527219525722-f9767a7f2884?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fGFkdmlzb3J5fGVufDB8fDB8fHwy' alt="Image on Left" />
        </div>
        <div className="text-container1">
          <h1 className='custheading'>Tailored Sustainability Consulting</h1>
          <ul>
          <Badge bg="badge">Sustainability reporting</Badge>
            <li>Partner with our expert team to develop and implement customized sustainability strategies for your corporation.</li>
            <li>Receive personalized advisory services to address your company's unique sustainability challenges and goals.</li>
            <li>Optimize environmental performance, enhance corporate sustainability, and mitigate your carbon footprint with our strategic guidance</li>
          </ul>
        </div>
      </div>
      
      {/* Second Section - Image on Right */}
      <div className="customer-container">
        <div className="text-container1">
          <h1 className='custheading'>Regulatory Compliance Assistance</h1>
          <ul>
          <Badge bg="badge">Adherence Importance</Badge>
            <li>Expert guidance to navigate complex carbon regulations and emissions reporting requirements.</li>
            <li>Ensuring corporate compliance with international standards such as ISO 14064 and GHG Protocol.</li>
            <li>Proactive monitoring of evolving regulatory landscapes to anticipate and address compliance challenges.</li>
          </ul>
        </div>
        <div className="image-container">
          {/* Insert your image here */}
          {/* Example: <img src="your-image-url.jpg" alt="Your Image" /> */}
          <img src='https://images.unsplash.com/photo-1580234033313-184d6414b800?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8Y2FyYm9uJTIwZm9vdHByaW50fGVufDB8fDB8fHww' alt="Image on Right" />
        </div>
      </div>

      {/* Third Section - Image on Left */}
      <div className="customer-container">
        <div className="image-container">
          {/* Insert your image here */}
          {/* Example: <img src="your-image-url.jpg" alt="Your Image" /> */}
          <img src='https://images.unsplash.com/photo-1460925895917-afdab827c52f?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8ZGF0YSUyMGRyaXZlbnxlbnwwfHwwfHx8MA%3D%3D' alt="Image on Left" />
        </div>
        <div className="text-container1">
          <h1 className='custheading'>Data-driven Decision Support</h1>
          <ul>
          <Badge bg="badge">Emission data</Badge>
            <li>Utilization of advanced analytics and modeling tools to identify emission hotspots and optimization opportunities.</li>
            <li>Predictive analytics for scenario planning and risk management to anticipate future emission challenges.</li>
            <li>Data-driven insights to support strategic decision-making and drive continuous improvement in carbon management efforts.</li>
          </ul>
        </div>
      </div>


      <section class="about-content">
<div>
    
    <div id="carouselBasicExample" class="carousel slide carousel-fade" data-mdb-ride="carousel">
        
    
        
        <Carousel>
            
        <Carousel.Item interval={2000}>
                <img src={images.home8} class="ab-tree"/>
                </Carousel.Item>
            
            <Carousel.Item interval={2000}>
                <img src={images.home10} class="ab-tree"/>
                </Carousel.Item>
            
            <Carousel.Item interval={2000}>
                <img src={images.home9} class="ab-tree"/>
                </Carousel.Item>
            </Carousel>
        
    </div>
    
</div>
<div>
<h1 >{t("ESG Analytics and Reporting.")}</h1>
    <p class="content">{t("Generate ESG reports aligned with internationally recognized sustainability frameworks.")}
                                                        
    <div class="about-table">
        <div>
            <img src='https://cdn-icons-png.flaticon.com/128/2401/2401314.png' class="ab-t"/>
        </div>
        <div>
            <h4 class="sub-heading">{t("Custom ESG Reports for your Business")}</h4>
            <p class="content">{t("Access tailored reports for your location and ESG factors, generate supply chain ESG reports, and offer stakeholders timely and precise ESG data.")}</p>
        </div>
    </div>
                                                    
    <div class="about-table">
        <div>
            <img src='https://cdn-icons-png.flaticon.com/128/11782/11782346.png' class="ab-t"/>
        </div>
        <div>
            <h4 class="sub-heading">{t("Measure your ESG Performance")}</h4>
            <p class="content">{t("Generate a Transparency Score based on global ESG standards, giving you insight into your ESG performance.")}</p>
        </div>
    </div>
                                                    
    <div class="about-table">
        <div>
            <img src='https://cdn-icons-png.flaticon.com/128/9504/9504695.png' class="ab-t"/>
        </div>
        <div>
            <h4 class="sub-heading"> {t("Get ahead of regulations")}</h4>
            <p class="content">{t("Compliance with mandatory disclosure regulations helps avoid fines and protects reputation.")}</p>
        </div>
    </div>
                                                                                                                                                                                                                    
    </p>
</div>
</section>



      <Card className="text-centercc">
      
      <Card.Body>
        <h2>Elevate Your Corporate Responsibility</h2>
        <p>
        Lead with purpose. Partner with us to implement strategic sustainability initiatives and drive positive change within your organization.
        </p>
        <button className="btn-orelse">Book a Demo</button>
      </Card.Body>
      
    </Card>

    </div>
    
    
  );
}

export default Customer1;
