import React from 'react';
import { data, images } from '../constants';
import '../pages/styles.css'
import { IconScroll } from '../components';

import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';

import ListGroup from 'react-bootstrap/ListGroup';

const Customer0 = () => {
  return (
    <div className="banner-pg">
    <div className="banner-pic">
      
      
      <div className="bannertitle">
        <h1>orelse for project developers</h1>
      </div>
    </div>
      {/* First Section - Image on Left */}
      <div className="customer-container">
        <div className="image-container">
         
          <img src='https://images.unsplash.com/photo-1523289333742-be1143f6b766?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MzR8fHByb2plY3R8ZW58MHx8MHx8fDA%3D' alt="Image on Left" />
        </div>
        
        <div className="text-container1">
        
          <h1 className='custheading'>Project Development Support</h1>
          <ul>
          <Badge bg="badge">Consulting services</Badge>
            <li>Collaborate with us to enhance the success of your sustainable initiatives.</li>
            <li>Receive expert advisory and consulting services tailored to your project's unique needs.</li>
            <li>Implement sustainable practices, improve environmental performance, and reduce your project's carbon footprint with our guidance.</li>
          </ul>
        </div>
      </div>
      
      {/* Second Section - Image on Right */}
      <div className="customer-container">
        <div className="text-container1">
          <h1 className='custheading'>Streamlined Reporting Systems</h1>
          <ul>
          <Badge bg="badge">ESG Reporting</Badge>
            <li>Generate comprehensive reports quickly and accurately.</li>
            <li>Simplify documentation and meet regulatory requirements.</li>
            <li>Ensure compliance with ease.</li>
          </ul>
        </div>
        <div className="image-container">
          {/* Insert your image here */}
          {/* Example: <img src="your-image-url.jpg" alt="Your Image" /> */}
          <img src='https://images.unsplash.com/photo-1434626881859-194d67b2b86f?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTl8fGRhdGElMjBkcml2ZW58ZW58MHx8MHx8fDA%3D' alt="Image on Right" />
        </div>
      </div>

      {/* Third Section - Image on Left */}
      <div className="customer-container">
        <div className="image-container">
          {/* Insert your image here */}
          {/* Example: <img src="your-image-url.jpg" alt="Your Image" /> */}
          <img src='https://images.unsplash.com/photo-1611224885990-ab7363d1f2a9?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8cHJvamVjdHxlbnwwfHwwfHx8MA%3D%3D' alt="Image on Left" />
        </div>
        <div className="text-container1">
          <h1 className='custheading'>Efficient Project Launch</h1>
          <ul>
          <Badge bg="badge">Success Partner</Badge>
            <li>Simplify project monitoring, reporting, and verification processes with our tools.</li>
            <li>Utilize cutting-edge technology for efficient analysis of project data.</li>
            <li>Ensure compliance with environmental regulations through streamlined reporting.</li>
          </ul>
        </div>

      </div>


      <div className='list-card'>
      <div>
          <h1 style={{textAlign: 'center'}}>Environmental Sustainability Integration</h1>
        </div>
    
    
      <div className="cc0cards" style={{ display: 'flex', justifyContent: 'start', overflowX: 'auto',paddingTop:'40px' }}>
    <div className="cc0card-container" style={{ display: 'flex', flexWrap: 'nowrap' }}>

      <Card className="cc0-card-indiv" style={{ flex: '0 0 auto', marginRight: '1rem' }}>
        <Card.Img variant="top" src='https://cdn-icons-png.flaticon.com/256/11826/11826915.png' />
        <Card.Body>
          <Card.Title>Environmental Impact Assessment</Card.Title>
        </Card.Body>
        <ListGroup className="list-group-flush">
          <ListGroup.Item>Evaluation</ListGroup.Item>
          <ListGroup.Item>Identification</ListGroup.Item>
          <ListGroup.Item>Mitigation</ListGroup.Item>
        </ListGroup>
      </Card>

      <Card className="cc0-card-indiv" style={{ flex: '0 0 auto', marginRight: '1rem' }}>
        <Card.Img variant="top" src='https://cdn-icons-png.flaticon.com/256/11826/11826874.png' />
        <Card.Body>
          <Card.Title>Sustainable Design and Construction</Card.Title>
        </Card.Body>
        <ListGroup className="list-group-flush">
          <ListGroup.Item>Efficiency</ListGroup.Item>
          <ListGroup.Item>Renewable Resources</ListGroup.Item>
          <ListGroup.Item>Optimal Orientation</ListGroup.Item>
        </ListGroup>
      </Card>


      <Card className="cc0-card-indiv" style={{ flex: '0 0 auto', marginRight: '1rem' }}>
        <Card.Img variant="top" src='https://cdn-icons-png.flaticon.com/256/11826/11826866.png' />
        <Card.Body>
          <Card.Title>Supply Chain Management</Card.Title>
        </Card.Body>
        <ListGroup className="list-group-flush">
          <ListGroup.Item>Local Sourcing</ListGroup.Item>
          <ListGroup.Item>Low Carbon Suppliers</ListGroup.Item>
          <ListGroup.Item>Transportation Efficiency</ListGroup.Item>
        </ListGroup>
      </Card>


      <Card className="cc0-card-indiv" style={{ flex: '0 0 auto', marginRight: '1rem' }}>
        <Card.Img variant="top" src='https://cdn-icons-png.flaticon.com/256/11826/11826875.png' />
        <Card.Body>
          <Card.Title>Energy Management</Card.Title>
        </Card.Body>
        <ListGroup className="list-group-flush">
          <ListGroup.Item>Efficiency Technologies</ListGroup.Item>
          <ListGroup.Item>Renewable Sources</ListGroup.Item>
          <ListGroup.Item>Smart Systems</ListGroup.Item>
        </ListGroup>
      </Card>


      <Card className="cc0-card-indiv" style={{ flex: '0 0 auto', marginRight: '1rem' }}>
        <Card.Img variant="top" src='https://cdn-icons-png.flaticon.com/256/11826/11826870.png' />
        <Card.Body>
          <Card.Title>Carbon Offsetting</Card.Title>
        </Card.Body>
        <ListGroup className="list-group-flush">
          <ListGroup.Item>Investment</ListGroup.Item>
          <ListGroup.Item>Reforestation</ListGroup.Item>
          <ListGroup.Item>Renewable Initiatives</ListGroup.Item>
        </ListGroup>
      </Card>

      <Card className="cc0-card-indiv" style={{ flex: '0 0 auto', marginRight: '1rem' }}>
        <Card.Img variant="top" src='https://cdn-icons-png.flaticon.com/256/11826/11826905.png' />
        <Card.Body>
          <Card.Title>Regulatory Compliance</Card.Title>
        </Card.Body>
        <ListGroup className="list-group-flush">
          <ListGroup.Item>Legal Requirements</ListGroup.Item>
          <ListGroup.Item>Environmental Standards</ListGroup.Item>
          <ListGroup.Item>Adherence Importance</ListGroup.Item>
        </ListGroup>
      </Card>

      <Card className="cc0-card-indiv" style={{ flex: '0 0 auto', marginRight: '1rem' }}>
        <Card.Img variant="top" src='https://cdn-icons-png.flaticon.com/256/11826/11826867.png' />
        <Card.Body>
          <Card.Title>Waste Management</Card.Title>
        </Card.Body>
        <ListGroup className="list-group-flush">
          <ListGroup.Item>Waste-to-Energy</ListGroup.Item>
          <ListGroup.Item>Methane Reduction</ListGroup.Item>
          <ListGroup.Item>Recycling</ListGroup.Item>
        </ListGroup>
      </Card>

    </div>
  </div>

  <IconScroll />

        </div>
         
         
         
         
         <div>
        <Card className="text-centercc">
      
      <Card.Body>
        <h2>Build a Better Tomorrow</h2>
        <p>
        Build sustainably, build smart. Collaborate with us to incorporate environmentally responsible practices into your projects and leave a lasting impact.
        </p>
        <button className="btn-orelse">Book a Demo</button>
      </Card.Body>
      
    </Card>
    </div>
    </div>
    
    
  );
}

export default Customer0;
