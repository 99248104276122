
import React from 'react';
import { images } from '../../constants'
import './Process.css'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from 'react-i18next';
import { Headings } from '../../components';


var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

const Process = () => {
  const { t } = useTranslation();
    return(
    <div className='process-container'>
      
        <Headings title={t("How it works")} text={t("At orelse, we specialize in developing customized solutions for managing carbon emissions. We optimize strategies to effectively reduce carbon footprints for our clients, using advanced and innovative technologies.")}/>
       
  
    <div className="slider-container">
      <Slider {...settings}>
        
        <div className="container-pp">
      <div className="numberBox">
        <span>1</span>
        <div className="textBox">
          <h1>------------</h1>
        </div>
      </div>
      <div className="content-pp">
        <h2>{t("Carbon Reduction Strategy Development")}</h2>
        <p>{t("orelse collaborates with clients to develop tailored strategies, setting clear goals and action plans for reducing carbon emissions.")}</p>
      </div>
    
        </div>


        <div className="container-pp">
      <div className="numberBox">
        <span>2</span>
        <div className="textBox">
          <h1>------------</h1>
        </div>
      </div>
      <div className="content-pp">
        <h2>{t("Emission Assessment and Target Setting")}</h2>
        <p>{t("Our tools evaluate current emissions and establish specific, achievable reduction targets.")}</p>
      </div>
    
        </div>



         <div className="container-pp">
      <div className="numberBox">
        <span>3</span>
        <div className="textBox">
          <h1>------------</h1>
        </div>
      </div>
      <div className="content-pp">
        <h2>{t("Customized Carbon Reduction Solutions")}</h2>
        <p>{t("orelse provides customized solutions, including software platforms and integration options, to effectively reduce carbon footprints.")}</p>
      </div>
    
        </div>


         <div className="container-pp">
      <div className="numberBox">
        <span>4</span>
        <div className="textBox">
          <h1>------------</h1>
        </div>
      </div>
      <div className="content-pp">
        <h2>{t("Performance Monitoring and Support")}</h2>
        <p>{t("Our monitoring systems track progress and offer continuous analysis and support to ensure clients achieve their emission reduction goals.")}</p>
      </div>
    
        </div>

        
      </Slider>
    </div>
  
    </div>
    );
}
export default Process;