import React, { useState, useEffect } from 'react';

const ScrollBookmark = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    // Retrieve scroll position from localStorage if available
    const savedScrollPosition = localStorage.getItem('scrollPosition');
    if (savedScrollPosition) {
      window.scrollTo(0, Number(savedScrollPosition)); // Restore scroll position on component mount
    }

    // Add event listener to track scroll position and update localStorage
    const handleScroll = () => {
      const position = window.scrollY;
      setScrollPosition(position);
      localStorage.setItem('scrollPosition', position);
    };
    window.addEventListener('scroll', handleScroll);

    // Remove event listener on cleanup
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); // Run only once on component mount

  return (
    <div>
      {/* Content of your component */}
    </div>
  );
};

export default ScrollBookmark;
