import React  from 'react';
import TradingViewWidget1 from '../components/TradingViewCharts/TradingViewWidget1';
import TradingViewWidget2 from '../components/TradingViewCharts/TradingViewWidget2';
import TradingViewWidget3 from '../components/TradingViewCharts/TradingViewWidget3';
import TradingViewWidget4 from '../components/TradingViewCharts/TradingViewWidget4';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import CarbonCalculator from '../components/CarbonCalculator';
import { useEffect } from 'react';

const NewPage = () => {

  const rescCardsData = [
    {
      title: 'New ASEAN Energy Partners with ACTUAL for Net-Zero Plan at $5 Billion Pengerang Energy Complex',
      imageSrc: 'https://images.unsplash.com/photo-1515614457209-3c7081d62743?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      content: 'PEC secures $102B agreements, targets low footprint with New ASEAN Energy and ACTUAL for net-zero plan, emphasizing green power, hydrogen, and carbon capture.',
      linkTo: 'https://esgnews.com/new-asean-energy-partners-with-actual-for-net-zero-plan-at-5-billion-pengerang-energy-complex/', // External link to navigate to when button is clicked
    },
    {
      title: 'Net-Zero Banking Alliance Tightens Guidelines for Banks’ Climate Targets',
      imageSrc: 'https://images.unsplash.com/photo-1562235323-cd8f789b0445?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      content: 'NZBA banks update guidelines, now covering capital markets emissions for the first time while maintaining original ambition for net zero by 2050.',
      linkTo: 'https://esgnews.com/net-zero-banking-alliance-tightens-guidelines-for-banks-climate-targets/', // External link to navigate to when button is clicked
    },

    {
      title: 'Nature Action 100 Unveils Guide to Help Investors Drive Corporate Action on Nature Loss',
      imageSrc: 'https://images.unsplash.com/photo-1460925895917-afdab827c52f?q=80&w=2015&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      content: 'Nature Action 100 has published a new field guide to equip investors with insights and resources to inform their nature engagements with companies',
      linkTo: 'https://esgnews.com/nature-action-100-unveils-guide-to-help-investors-drive-corporate-action-on-nature-loss/', // External link to navigate to when button is clicked
    },

    {
      title: 'MSCI Study Reveals Heavier Net Zero Transition Opportunities Exposure for Private vs Public Climate Funds',
      imageSrc: 'https://images.unsplash.com/photo-1633158829875-e5316a358c6f?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      content: 'While the number of climate-related investment funds has surged over the past few years, significant differences in composition between private and public markets climate funds have emerged',
      linkTo: 'https://www.esgtoday.com/msci-study-reveals-heavier-net-zero-transition-opportunities-exposure-for-private-vs-public-climate-funds/', // External link to navigate to when button is clicked
    },

    {
      title: 'ESG Voice: Skillfulness Delivered - The Importance of ESG Education',
      imageSrc: 'https://images.unsplash.com/photo-1562564055-71e051d33c19?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      content: 'ESG education has emerged as a strategic imperative that both shapes the future resilience and relevance of any enterprise, and drives value creation across industries.',
      linkTo: 'https://www.knowesg.com/featured-article/esg-voice-skillfulness-delivered-the-importance-of-esg-education', // External link to navigate to when button is clicked
    },

    {
      title: 'Blockchain and the Importance of Sustainability in New Age Technology',
      imageSrc: 'https://images.unsplash.com/photo-1605792657660-596af9009e82?q=80&w=2002&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      content: 'Sustainability in the new age tech can be driven through disruptive innovation buttressed by societal transformations toward a more sustainable and equitable world. Blockchain is one powerful way of doing this.',
      linkTo: 'https://www.knowesg.com/crypto/blockchain-and-the-importance-of-sustainability-in-new-age-technology-29102022', // External link to navigate to when button is clicked
    },

    {
      title: 'Five KPIs that businesses use to measure sustainability',
      imageSrc: 'https://plus.unsplash.com/premium_photo-1682310539039-1a65c8fc0b4a?q=80&w=1824&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      content: 'Data has become the most important tool for analysing business sustainability, here are some of the KPIs that data is measured against',
      linkTo: 'https://sustainabilitymag.com/diversity-and-inclusion-dandi/five-kpis-businesses-use-measure-sustainability', // External link to navigate to when button is clicked
    },

    {
      title: 'How Panasonic is Using R&D to Accelerate Towards Net Zero',
      imageSrc: 'https://images.unsplash.com/photo-1531403009284-440f080d1e12?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      content: 'Panasonic’s leaders want research and development to have a central role in the Japan-based firm’s drive to be carbon neutral by 2050',
      linkTo: 'https://sustainabilitymag.com/articles/how-panasonic-is-using-r-d-to-accelerate-towards-net-zero', // External link to navigate to when button is clicked
    },

    {
      title: 'A beginner guide to developing your company ESG strategy',
      imageSrc: 'https://images.unsplash.com/photo-1521618755572-156ae0cdd74d?q=80&w=1776&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      content: 'Modern investors use many non-financial measures to decide which companies to back, and ESG strategies have become a crucial barometer of long term returns',
      linkTo: 'https://sustainabilitymag.com/sustainability/a-beginners-guide-to-developing-your-companys-esg-strategy-environmental-social-governance', // External link to navigate to when button is clicked
    },
    // Add more objects for additional cards
  ];
  useEffect(() => {
  const hash = window.location.hash;
  if (hash) {
    const element = document.getElementById(hash.replace('#', ''));
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }
}, []);
  return (
    <div>
      
      <div className="banner-pg" >
       <div className="bannertitle" id="carboncal">
    <h1>Resources</h1>
  </div>
  </div>
  <div>
    <div className='resources-page'>
    <h2 className='chart-title' style={{ paddingBottom: '20px' }}>Personal Carbon Calculator</h2>
    <CarbonCalculator/>
    </div>
     <div id="blogs">
      <div>
      <h2 className='chart-title' style={{ paddingBottom: '20px',paddingTop:'40px' }}>Articles</h2>
     <div >
     <Row xs={1} md={3} className="g-4">
     {rescCardsData.map((card, idx) => (
          <Col key={idx}>
           <Card className='resouces-cards'>
           <Card.Img variant="top" src={card.imageSrc} />
              <Card.Body>
                <Card.Title>{card.title}</Card.Title>
                <Card.Text>{card.content}</Card.Text>
                <button className='btn-orelse' >
                  <a href={card.linkTo} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color:'#fff' }}>Read more</a>
                </button>
              </Card.Body>
    </Card>
    
        </Col>
      ))}
    </Row>
     </div>
     </div>
  </div>
<div id="charts">
<h2 className='chart-title' style={{ paddingTop: '50px' }}>Charts</h2>
    <h3 className='chart-title' style={{ paddingTop: '20px' }}>Nature Based Carbon Offset</h3>
    <div className='container-trade-charts'>
    <TradingViewWidget2 />
    </div>
    <h3 className='chart-title' style={{ paddingTop: '50px' }}>Global Emissions Offset trailing</h3>
    <div className='container-trade-charts'>
    <TradingViewWidget3 />
    </div>
    <h3 className='chart-title' style={{ paddingTop: '50px' }}>Tech Industry Carbon Offset</h3>
    <div className='container-trade-charts'>
    <TradingViewWidget4 />
    </div>

    <h3 className='chart-title' style={{ paddingTop: '50px' }}>Aviation Industry Carbon Offset</h3>
    <div className='container-trade-charts'>
    <TradingViewWidget1 />
    </div>
   
    </div>
    </div>
    </div>
  );
}

export default NewPage;




