import React from 'react';
import { Headings } from '../../components';
import { data } from '../../constants';
import { BsFillArrowUpRightCircleFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';

import './Solutions.css';
import { useTranslation } from 'react-i18next';


const Solutions = () => {
  const { t } = useTranslation(); 
  return (
    
    <div id="solutions" className="d-block-pt-md-4">
      <Headings title={t("Solutions")} text={t("Explore orelse's Range of Offerings, Tailored for Every Need.")} />

      <div className="row">
        
        {data.SolutionAvail.map(({ titleone, titletwo , link, itemclass, imgURL }, index) => (
          
          <div className="col-lg-3 col-12" key={index}>
            
          <div className={`row ${itemclass}`}
          onClick={() => { window.location.href = link; }}>
              <div className="col-md-12 box">
             <div>  <span>{t(titleone)} </span> 
               <span>{t(titletwo)}</span> 
               </div>
               <span alt={titleone} className="learnmore"><BsFillArrowUpRightCircleFill /> </span>
              </div>
             
              <div className="col-md-8 text-end"><img src={imgURL} alt={titleone} className="img-fluid img-services" /></div>
             
            </div>
            
          </div>
         
        ))}
      </div>
      
    </div>
    
  )
}

export default Solutions