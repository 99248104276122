import React, { useState, useEffect, createContext, useContext } from 'react';
import LanguageSelectorPopup from '../components/LanguageSelectorPopup';
import i18n from '../components/i18n'; // Import your i18n configuration

// Create a context for managing language state
const LanguageContext = createContext();

// Custom hook to access language context
export const useLanguageContext = () => useContext(LanguageContext);

const withLanguagePopup = (WrappedComponent) => {
  return () => {
    const [showLanguageSelector, setShowLanguageSelector] = useState(false);
    const [language, setLanguage] = useState(localStorage.getItem('userLanguage') || 'en');

    useEffect(() => {
      const userLanguage = localStorage.getItem('userLanguage');
      if (!userLanguage) {
        setShowLanguageSelector(true);
      } else {
        i18n.changeLanguage(userLanguage); // Set i18n language on component mount
      }
    }, []);

    const handleLanguageSelect = (selectedLanguage) => {
      localStorage.setItem('userLanguage', selectedLanguage);
      setLanguage(selectedLanguage);
      setShowLanguageSelector(false);
      i18n.changeLanguage(selectedLanguage); // Update i18n language when user selects a language
    };

    return (
      <LanguageContext.Provider value={{ language, setLanguage }}>
        <React.Fragment>
          <WrappedComponent />
          {showLanguageSelector && <LanguageSelectorPopup onSelect={handleLanguageSelect} />}
        </React.Fragment>
      </LanguageContext.Provider>
    );
  };
};

export default withLanguagePopup;
