import React,{ useEffect, useState,useRef } from 'react';
import { useTranslation } from 'react-i18next';
import './ParallaxHome.css'
import { BsFillArrowUpRightCircleFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';

const AnimateNumber = ({ from, to, duration, children }) => {
  const ref = useRef();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.5 } // You can adjust the threshold as needed
    );

    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    if (isVisible) {
      let start = null;
      const animate = (timestamp) => {
        start = start || timestamp;
        const progress = Math.min((timestamp - start) / duration, 1);
        const value = progress * (to - from) + from;
        children(Math.floor(value));
        if (progress < 1) {
          window.requestAnimationFrame(animate);
        }
      };
      window.requestAnimationFrame(animate);
    }
  }, [from, to, duration, children, isVisible]);

  return <div ref={ref}>{isVisible && children}</div>;
};

const ParallaxHome = () => {
 
  const { t } = useTranslation(); 

    return (
        <div className="ParallaxContainer">
         
         
        
          <section id="skill">
  <div className="skill__inner">
    <h2 className="skill__title">
    {t("What we do")} <span>{t("Are you looking to reduce your carbon footprint and make a positive impact on the environment?")}</span>
    </h2>
    <div className="skill__desc">
     
      <div>
        <span>1. </span>
        <h3>{t("Create Net Zero Strategy")}</h3>
        <p>{t("Go green for the future! orelse helps you map out a plan to reduce your company's carbon footprint and reach net zero emissions.")}</p>
    
        <Link to={'#'} alt='learn' className="wwd-learnmore" style={{ textDecoration: 'none', position: 'relative', color: '#fff' }}><BsFillArrowUpRightCircleFill /> {t("Learn more")}
        </Link>
      </div>
      <div>
        <span>2. </span>
        <h3>{t("Regulatory Compliance & Policy Advisory")}</h3>
        <p>{t("Sustainability regulations can be tricky, but orelse is here for you. orelse keeps you on the right side of the law and even helps you shape future policies for a greener world.")}</p>
      </div>
      <Link to={'#'} alt='learn' className="wwd-learnmore" style={{ textDecoration: 'none', position: 'relative', color: '#fff' }}><BsFillArrowUpRightCircleFill /> {t("Learn more")}
        </Link>

      <div>
        <span>3. </span>
        <h3>{t("ESG & Climate Strategy Consulting")}</h3>
        <p>{t("Make your business a force for good! orelse shows you how to integrate environmental, social, and governance practices into your strategy for long-term success.")}</p>
    
        <Link to={'#'} alt='learn' className="wwd-learnmore" style={{ textDecoration: 'none', position: 'relative', color: '#fff' }}><BsFillArrowUpRightCircleFill /> {t("Learn more")}
        </Link>
      </div>

      <div>
        <span>4. </span>
        <h3>{t("Environmental Credit Solutions")}</h3>
        <p>{t("Minimize your impact, maximize the good! Find ways to offset any unavoidable emissions and support environmental projects that make a positive difference.")}</p>
    
        <Link to={'#'} alt='learn' className="wwd-learnmore" style={{ textDecoration: 'none', position: 'relative', color: '#fff' }}><BsFillArrowUpRightCircleFill /> {t("Learn more")}
        </Link>
      </div>

      <div>
        <span>5. </span>
        <h3>{t("Achieve your sustainability goals")}</h3>
        <p>{t("Ready to make a difference? orelse helps you turn your sustainability dreams into a clear and achievable action plan, complete with ways to track your progress.")}</p>
    
        <Link to={'#'} alt='learn' className="wwd-learnmore" style={{ textDecoration: 'none', position: 'relative', color: '#fff' }}><BsFillArrowUpRightCircleFill /> {t("Learn more")}
        </Link>
      </div>

    </div>
  </div>
</section>


<section class="features">
      <div class="container">
        <div class="col-lg-8 col-md-12 col-sm-12 col-xs-12 mx-auto text-center">
          <h1 class="title ">{t("Manage your decarbonisation journey effortlessly")}</h1>
  
        </div>
        <div class="featuresflexbox fnt-reg">
  
                                  
          <div class="featuresboxt">
            <div class="card">
              <img src='https://cdn-icons-png.flaticon.com/128/13284/13284891.png' class="card-img-top" alt="..."/>
              <hr class="h-line"/>
              <div class="card-body">
                <h5 class="sub-heading">{t("Measure")}</h5>
                <p class="card-text">{t("Evaluate your carbon footprint to get a clear picture of your environmental impact and areas for improvement.")}</p>
              </div>
            </div>
          </div>
                                  
          <div class="featuresboxt">
            <div class="card">
              <img src='https://cdn-icons-png.flaticon.com/128/7822/7822047.png' class="card-img-top" alt="..."/>
              <hr class="h-line"/>
              <div class="card-body">
                <h5 class="sub-heading">{t("Reduce")}</h5>
                <p class="card-text">{t("Minimize your carbon footprint by adopting simple yet effective strategies and making eco-conscious decisions in your operations.")}</p>
              </div>
            </div>
          </div>
                                  
          <div class="featuresboxt">
            <div class="card">
              <img src='https://cdn-icons-png.flaticon.com/128/7525/7525098.png' class="card-img-top" alt="..."/>
              <hr class="h-line"/>
              <div class="card-body">
                <h5 class="sub-heading">{t("Offset")}</h5>
                <p class="card-text">{t("Compensate for unavoidable emissions by supporting initiatives that promote environmental sustainability and carbon neutrality.")}</p>
              </div>
            </div>
          </div>
                                                                                                                    
  
        </div>
        </div>
    </section>


<section>
  <div className="carbon-source-title">
    <h1 className='title' style={{paddingTop:'60px'}}>{t("Understanding Major Carbon Emission Sources")} </h1>

    </div>
        <div className="timeline-container">
         
        <div className="entries">
          <div className="entry">
            <div className="title">1.CHINA</div>
            <div className="body20">
            <p>Energy (Coal-fired Power) - 50.7%</p>
<p>Industry (Manufacturing) - 17.9%</p>
<p>Agriculture - 14.5%</p>
    </div>
  </div>
  <div className="entry">
    <div className="title">2.UNITED STATES</div>
    <div className="body20">
    <p>Energy (Fossil Fuel Power) - 34.2%</p>
<p>Transportation - 28.6%</p>
<p>Industry (Manufacturing) - 13.1%</p>
    </div>
  </div>
  <div className="entry">
    <div className="title big">3.INDIA</div>
    <div className="body20">
    <p>Energy (Coal-fired Power) - 70.2%</p>
<p>Industry (Manufacturing) - 12.4%</p>
<p>Agriculture - 11.8%</p>
    </div>
  </div>
  <div className="entry">
    <div className="title">4.RUSSIA</div>
    <div className="body20">
    <p>Energy (Oil & Gas) - 62.1%</p>
<p>Industry (Manufacturing) - 19.3%</p>
<p>Agriculture - 7.8%</p>
    </div>
  </div>
  <div className="entry">
    <div className="title">5.JAPAN</div>
    <div className="body20">
    <p>Energy (Fossil Fuel Power) - 38.5%</p>
<p>Industry (Manufacturing) - 23.7%</p>
<p>Transportation - 16.2%</p>
    </div>
  </div>
  <div className="entry">
    <div className="title big">6.BRAZIL</div>
    <div className="body20">
    <p>Agriculture (Land-Use Change) - 72.9%</p>
<p>Energy (Fossil Fuel Power) - 10.3%</p>
<p>Industry (Manufacturing) - 8.2%</p>
    </div>
  </div>
  <div className="entry">
    <div className="title">7.INDONESIA</div>
    <div className="body20">
    <p>Agriculture (Land-Use Change) - 61.4%</p>
<p>Energy (Fossil Fuel Power) - 22.1%</p>
<p>Industry (Manufacturing) - 9.8%</p>
    </div>
  </div>
</div>


    </div>
      </section>


<section className='carbon-number'>
  
  <div style={{ height: '200px' }}>
     
      <strong></strong>
      <AnimateNumber from={0} to={36.8} duration={4000}>
        {(value) => { 
          document.querySelector('strong').textContent = `${value} billion tonnes of carbon emissions annually`;
          
        }}
        
      </AnimateNumber>
      
    </div>
    <h2>Don't Just Talk Sustainability, Achieve It. </h2>
      <h3>Partner with orelse and explore our solutions to Be Part of the Change.</h3>
      
</section>


      </div>
    );
  }
export default ParallaxHome;
