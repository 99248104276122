
import React from "react";
import Accordion from 'react-bootstrap/Accordion';
import { images } from '../../constants';
import { Link } from "react-router-dom";



const CarbonManagement=()=> {

    const FeatureProduct = ({ imgSrc, title, desc }) => {
        return (
          <div>
            <img
              src={imgSrc}
              width={100}
              height={100}
              alt="features"
              className="mx-auto"
            />
            <h3 className="text-2xl font-semibold">{title}</h3>
            <p>{desc}</p>
          </div>
        );
      };
      

      const QualityFeature = ({ imgSrc, title, desc }) => {
        return (
          <div className="flex items-start lg:flex-row sm:flex-col gap-1 sm:gap-4 lg:gap-8">
            <img src={imgSrc} width={80} height={80} alt="features" />
            <div className="leading-loose">
              <h3 >{title}</h3>
              <p>{desc}</p>
            </div>
          </div>
        );
      };
      

      const WorkCard = ({ num, title,desc }) => {
        return (
          <div className="w-[90%] sm:w-4/5 mx-auto md:mx-0 md:w-full flex flex-col md:gap-5 gap-3 text-center md:text-left">
            <span className="md:mx-0 mx-auto text-3xl w-fit font-bold text-black bg-white rounded-full py-4 px-4">
              {num}
            </span>
            <h2 className="text-xl font-semibold leading-relaxed">{title}</h2>
            <p className="leading-loose">
              {desc}
            </p>
          </div>
        );
      };
    return (

        
      <section className="mt-14">


  {/*Hero section*/}      
      
<section className="container mx-auto px-4 md:px-16 flex flex-col lg:flex-row items-center lg:gap-20 gap-14 mt-36">
  <div className="flex flex-col gap-2 flex-1">
    <span className="text-green-600 uppercase block font-semibold tracking-widest">
      CARBON MANAGEMENT
    </span>
    <h2 className="capitalize text-3xl md:text-4xl xl:text-5xl font-bold my-3">
    Strategic Advisory for Environmental Excellence
    </h2>
    <p className="text-lg leading-loose">
    Gain a competitive edge with our advisory services, offering tailored solutions for carbon management, GHG reduction, and sustainable business practices aligned with your goals and values.
    </p>
    <button className="mt-5 w-fit md:text-base text-sm hover:border-2 border-2 border-transparent font-semibold py-3 px-8 md:px-10 text-white bg-black rounded-full">
      Explore
    </button>
  </div>

  <div className="flex-1 relative w-full">
    <img
      src={"https://cdn-icons-png.flaticon.com/256/10294/10294874.png"}
      alt="core features"
      className="object-cover w-full md:w-[90%] xl:w-4/5 h-full"
      width={300}
      height={300}
    />
  </div>
</section>

{/*more features */}

<section className="mx-auto px-5 md:px-16 mt-28 mb-14">
      
      <h2 className="title text-center ">Services Offered</h2>

      <div className="grid gap-x-10 gap-y-16 sm:grid-cols-2 mt-10 md:mt-20 lg:w-[88%] mx-auto">
        <QualityFeature
          imgSrc="https://cdn-icons-png.flaticon.com/128/4850/4850808.png"
          title="Carbon Footprint Assessment"
          desc="Our comprehensive carbon footprint assessments identify opportunities to cut emissions and optimize processes. This translates to both environmental and financial benefits for your organization."
        />
        <QualityFeature
          imgSrc="https://cdn-icons-png.flaticon.com/128/13284/13284945.png"
          title="GHG Accounting and Reporting"
          desc="Accurately quantify your organization's greenhouse gas emissions, gaining a clear picture of your environmental footprint."
        />
        <QualityFeature
          imgSrc="https://cdn-icons-png.flaticon.com/128/14228/14228646.png"
          title="Compliance & Policy Advisory"
          desc="tay ahead of the curve with our up-to-date knowledge of evolving GHG reporting regulations. We'll ensure your reports meet all current requirements, avoiding any potential compliance issues."
        />
        <QualityFeature
          imgSrc="https://cdn-icons-png.flaticon.com/128/15382/15382506.png"
          title="Emission Reduction Strategy"
          desc="Create a customized emission reduction plan that supports your company's goals. Our advisors work with you to put specific actions into place that minimize your environmental effect and maximize your operational effectiveness."
        />
      </div>
    </section>
  
{/*More section */}

<section className="container mx-auto px-4 md:px-16 flex flex-col lg:flex-row items-center lg:gap-20 gap-14 mt-36">
  <div className="flex-1 relative w-full">
    <img
      src={"https://cdn-icons-png.flaticon.com/256/10294/10294876.png"}
      alt="core features"
      className="object-cover w-full md:w-[90%] xl:w-3/5 h-full"
      width={300}
      height={300}
    />
  </div>

  <div className="flex flex-col gap-2 flex-1">
    
    <h2 className="capitalize text-3xl md:text-4xl xl:text-5xl font-bold my-3">
    Empowering Businesses for Green Leadership
    </h2>
    <p className="text-lg leading-loose">
    Join us in leading the charge for sustainability as we provide actionable insights and expert consultancy to help your organization thrive while making a meaningful impact on the planet.
    </p>
  </div>
</section>

<section className="container mx-auto px-4 md:px-16 flex flex-col lg:flex-row-reverse items-center lg:gap-20 gap-14 mt-36">
  <div className="flex-1 relative w-full ">
    <img
      src={"https://cdn-icons-png.flaticon.com/256/11826/11826915.png"}
      alt="core features"
      className=" w-full md:w-[100%] xl:w-3/5 h-full"
      width={300}
      height={300}
    />
  </div>

  <div className="flex flex-col gap-2 flex-1">
    
    <h2 className="capitalize text-3xl md:text-4xl xl:text-5xl font-bold my-3">
    Elevating Your GHG Reporting Standards
    </h2>
    <p className="text-lg leading-loose">
    Partner with us to elevate your GHG reporting standards, ensuring accuracy, transparency, and alignment with international protocols for credible emissions disclosure.
    </p>
  </div>
</section>

{/*process */}
<section className="w-full bg-black text-white mt-36">
      <div className="flex flex-col gap-10 lg:gap-16 container mx-auto md:px-16 px-5 py-12 sm:py-20 md:py-16">
        <div>
         
          <h2 className="title text-center">
            Let&apos;s see how it works
          </h2>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-10 md:gap-5">
          <div className="relative">
            <WorkCard num="01" title="Enhance Transparency" desc="Showcase your commitment to sustainability by presenting a transparent and accurate picture of your emissions. " />
            
          </div>

          <div className="relative">
            <WorkCard
              num="02"
              title="Manage Risks" desc="Proactively identify areas for emission reduction and mitigation to stay ahead of evolving regulations and avoid potential penalties. "
            />
            
          </div>
          <div className="relative">
            <WorkCard num="03" title="Improve Efficiency" desc="Gain valuable insights from your GHG data to identify areas for operational optimization and cost savings. "/>
            
          </div>
          <WorkCard num="04" title="Make Informed Decisions" desc="Develop a data-driven sustainability strategy with realistic reduction targets and a roadmap for achieving them. "/>
        </div>
      </div>
    </section>

{/*features */}

<section className=" mx-auto px-5 md:px-16 mt-28" id="features">
    
    <h2 className="title text-center">Why choose us</h2>

    <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-4 md:gap-5 text-center mt-10 md:mt-20">
      <FeatureProduct
        imgSrc="https://cdn-icons-png.flaticon.com/128/993/993760.png"
        title="Tailored Solutions"
        desc="Every business has its own sustainability journey. Our consultants work closely with each client to develop a sustainability strategy that's right for them."
      />
      <FeatureProduct
        imgSrc="https://cdn-icons-png.flaticon.com/128/8920/8920512.png"
        title="Expertise"
        desc="Our team brings deep sustainability knowledge to the table. They leverage their expertise to craft customized solutions that propel you towards sustainable practices."
      />
      <FeatureProduct
        imgSrc="https://cdn-icons-png.flaticon.com/128/13651/13651293.png"
        title="Sustained partnership"
        desc="Sustainability is a continuous journey. We're here to be your partner every step of the way, supporting your evolving sustainability goals."
      />
      <FeatureProduct
        imgSrc="https://cdn-icons-png.flaticon.com/128/2092/2092291.png"
        title="Technical Efficiency"
        desc="Combine powerful tech solutions with our expert advisors for a winning formula. We'll help you achieve your sustainability goals in the most efficient way possible."
      />
    </div>
  </section>

    <section className="mx-auto px-5 md:px-16 mt-24 mb-14">
      
      <h2 className="title text-center ">Frequently asked questions</h2>

    <Accordion >
      <Accordion.Item eventKey="0">
        <Accordion.Header>How can your consulting service help my business with carbon management?</Accordion.Header>
        <Accordion.Body>
        Our consulting service offers tailored strategies to assess your carbon footprint, identify areas for improvement, implement carbon reduction initiatives, and develop sustainable practices. We guide you through the process, ensuring compliance and maximizing environmental impact.
          
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>What is GHG Protocol</Accordion.Header>
        <Accordion.Body >
        he Greenhouse Gas protocol is the most widely used and internationally recognized greenhouse gas accounting standard.he GHG protocol divides emissions into three scopes:

Scope 1: Direct emissions from operations owned or controlled by the reporting organization, such as on-site fuel combustion or fleet vehicles.
Scope 2: Indirect emissions related to emission generation of purchased energy, such as heat and electricity.
Scope 3: Other indirect emissions from all the other activities you are engaged in. Including all emissions from an organization's supply chain; business travel for employees, and the electricity customers may consume when using your product.
          
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header >What types of organizations can benefit from carbon management consulting?</Accordion.Header>
        <Accordion.Body >
        Our services cater to businesses of all sizes across various industries, including manufacturing, hospitality, transportation, energy, and more. Whether you're a multinational corporation or a small startup, our expertise can help you achieve your sustainability goals.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>What are greenhouse gases (GHGs), and why are they significant in carbon management?</Accordion.Header>
        <Accordion.Body >
        Greenhouse gases are gases in the Earth's atmosphere that trap heat, contributing to the greenhouse effect and global warming. Common GHGs include carbon dioxide (CO2), methane (CH4), nitrous oxide (N2O), and fluorinated gases. Understanding GHGs is crucial in carbon management because they are primary contributors to climate change, and managing their emissions is essential for sustainability efforts.
          
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header>How can I get started with your consulting services?</Accordion.Header>
        <Accordion.Body >
        Simply reach out to us through our website or contact information provided. We'll schedule an initial consultation to discuss your needs, assess your current carbon management practices, and develop a customized plan to help your business thrive sustainably.
          
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>


</section>
{/*ending cta */}
    <section className="container mx-auto px-5 md:px-10">
      <div className="bg-gray-200 rounded-lg text-black py-16 sm:py-8">
        <div className="flex flex-col items-center gap-4 sm:gap-6 w-[90%] md:w-4/5 lg:w-1/2 text-center mx-auto">
          <h2 className="text-2xl sm:text-4xl font-semibold">
          Let’s make things happen
          </h2>
          <p className="leading-loose font-[500]">
          Contact us today to discover how our carbon management solutions can empower your business to reduce its environmental footprint and thrive in a sustainable future.
          </p>
         
           
            <button
             
              className="w-full mt-2  capitalize  sm:top-2 md:top-[.4rem] sm:right-2 md:text-base text-sm hover:border-2 border-2 border-transparent font-semibold py-4 px-7 text-white bg-black  rounded-full"
            >
              Book a Demo
            </button>
          </div>
        </div>
      
    </section>

</section>
    )
}
        
  export default CarbonManagement;