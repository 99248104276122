import React from "react";
 
import './privacstyles.css';
const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
            <header className="privacy-header">
                <h1>Privacy Policy</h1>
            </header>
      <div className="privacy-content">
       <p> <h2  >WHAT DO WE DO WITH YOUR INFORMATION?</h2>
        <ul>
        <li>
          When you purchase something from our store, as part of the buying and selling process, we collect the personal information you give us such as your name, address, and email address.
        </li>
        <li>
          When you browse our store, we also automatically receive your computer’s internet protocol (IP) address to help us learn about your browser and operating system.
        </li>
        <li>
          Email marketing (if applicable): With your permission, we may send you emails about our store, new products, and other updates.
        </li>
        </ul>
        </p>
        <p>
        <h2 >CONSENT</h2>
         
        <h4>How do you get my consent?</h4>
        <ul>
          <li>When you provide us with personal information to complete a transaction, verify your credit card, place an order, arrange for a delivery, or return a purchase, we imply that you consent to our collecting it and using it for that specific reason only.</li>
         
        <li>
          If we ask for your personal information for a secondary reason, like marketing, we will either ask you directly for your expressed consent or provide you with an opportunity to say no.
        </li>

        </ul>
        </p>
        <p>
        <h4>How do I withdraw my consent?</h4>
          If, after you opt-in, you change your mind, you may withdraw your consent for us to contact you, for the continued collection, use, or disclosure of your information, at any time by contacting us at <strong>vishal@orelse.ai</strong> or mailing us at: <strong> vishal@orelse.ai </strong>.
        </p>
        <p>
        <h2  > DISCLOSURE</h2>
        
          We may disclose your personal information if required by law or if you violate our Terms of Service.
        </p>
        <p>
        <h2  > PAYMENT</h2>
        <ul>
          <li>
          We use Razorpay for processing payments. Razorpay does not store your card data on their servers. The data is encrypted through the Payment Card Industry Data Security Standard (PCI-DSS) during payment processing. Your purchase transaction data is only used as long as necessary to complete your transaction. After that, your purchase transaction information is not saved.
          </li>
        <li>
          For more information, you may also want to read Razorpay's terms at <a href="https://razorpay.com" className="link">https://razorpay.com</a>.
        </li>
        </ul>
        </p>
        <p><h2  > THIRD-PARTY SERVICES</h2>
        <ul>
        <li>
          In general, third-party providers used by us will only collect, use, and disclose your information to the extent necessary to allow them to perform the services they provide to us.
        </li>
        <li>
          However, certain third-party service providers, such as payment gateways, have their own privacy policies regarding the information we are required to provide to them for purchase-related transactions. We recommend you read their privacy policies to understand how your information will be handled.
        </li>
        </ul>
        </p>
        <p>
        <h2  > SECURITY</h2>
        
          To protect your personal information, we take reasonable precautions and follow industry best practices to ensure it is not inappropriately lost, misused, accessed, disclosed, altered, or destroyed.
         
        </p>
        <p>
        <h2  > COOKIES</h2>
         
          We use cookies to maintain the session of your user. They are not used to personally identify you on other websites.
        </p>
        <p>
        <h2 >AGE OF CONSENT</h2>
        
          By using this site, you represent that you are at least the age of majority in your state or province of residence or that you are the age of majority and you have given us consent to allow any of your minor dependents to use this site.
        </p>
        <p>
        <h2  > CHANGES TO THIS PRIVACY POLICY</h2>
        
          We reserve the right to modify this privacy policy at any time. Changes and clarifications will take effect immediately upon their posting on the website.
        </p>
        <p>
        <h2  >QUESTIONS AND CONTACT INFORMATION</h2>
        
          If you would like to access, correct, amend, or delete any personal information we have about you, register a complaint, or simply want more information, contact our Privacy Compliance Officer at <strong>vishal@orelse.ai</strong> or mail us at <strong> vishal@orelse.ai </strong>.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;