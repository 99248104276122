import React from 'react';
import { data, images } from '../constants';
import '../pages/styles.css'

import Card from 'react-bootstrap/Card';
import Carousel from 'react-bootstrap/Carousel';
import Badge from 'react-bootstrap/Badge';

import { FarmerContactForm } from '../container';

const Customer3 = () => {
  return (
    <div className="banner-pg">
    <div className="banner-pic">
      
      
      <div className="bannertitle">
        <h1>Path Towards Sustainable Agriculture</h1>
      </div>
    </div>
      {/* First Section - Image on Left */}
      <div className="customer-container">
        <div className="image-container">
        
          <img src='https://images.unsplash.com/photo-1627920768905-575535d6dd2e?q=80&w=1933&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D' alt="Image on Left" />
        </div>
        <div className="text-container1">
          <h1 className='custheading'>Simplified Carbon Tracking and Reporting</h1>
          <ul>
          <Badge bg="badge"> Carbon Management</Badge>
            <li>Simplify carbon tracking and reporting for your farm with our innovative suite of tools.</li>
            <li>Utilize cutting-edge technology to monitor and analyze your farm's carbon emissions.</li>
            <li>Enable streamlined reporting and compliance with environmental regulations.</li>
          </ul>
        </div>
      </div>
      
      {/* Second Section - Image on Right */}
      <div className="customer-container">
        <div className="text-container1">
          <h1 className='custheading'>Access to Carbon Offsets for Sustainable Impact</h1>
          <ul>
          <Badge bg="badge">Carbon Offsetting</Badge>
            <li>Offset your farm's emissions and contribute to a sustainable future.</li>
            <li>Gain access to carbon credits supporting environmental projects like reforestation and renewable energy initiatives.</li>
            <li>Offset your farm's carbon footprint and drive positive environmental impact.</li>
          </ul>
        </div>
        <div className="image-container">
        
          <img src='https://images.unsplash.com/photo-1464972377689-e7674c48d806?q=80&w=2055&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D' alt="Image on Right" />
        </div>
      </div>

      {/* Third Section - Image on Left */}
      <div className="customer-container">
        <div className="image-container">
          
          <img src='https://images.unsplash.com/photo-1518278364075-eb43498f2a73?q=80&w=1882&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D' alt="Image on Left" />
        </div>

        <div className="text-container1">
          <h1 className='custheading'>Empowering Sustainable Farming Initiatives</h1>
          <ul>
          <Badge bg="badge">Advisory Services</Badge>
            <li>Partner with us to enhance your farm's sustainability efforts.</li>
            <li>Receive expert advisory and consulting services tailored to your farm's unique needs.</li>
            <li>Implement sustainable farming practices, improve environmental performance, and reduce your carbon footprint with our guidance.</li>
          </ul>
        </div>
          </div>
    <div className='list-card'>
          <h1 style={{textAlign: 'center'}}>Contributing Factors to Agricultural Carbon Emissions</h1>
          <div className='pollutant-cards'>
            
          <Carousel data-bs-theme="dark" style={{background:'#000'}}>
            <Carousel.Item interval={1500}>
            <div className="carousel-image-wrapper">
            <img
                className='carousel-image'
                src='https://images.unsplash.com/photo-1681372190756-6626691159fe?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
                alt="First slide"
              />
              </div>
              <Carousel.Caption style={{ lineHeight: '1.2', backgroundColor: 'rgba(0, 0, 0, 0.5)', color: 'white', padding: '10px' }}>
                <h3>Using Fuel for Machines</h3>
                <p >Farm machines burn fuel, emitting carbon dioxide and contributing to climate change.</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={1500}>
            <div className="carousel-image-wrapper">
            <img
                className='carousel-image'
                src='https://images.unsplash.com/photo-1573865174887-dd85bc60e3e5?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
                alt="First slide"
              />
              </div>
              <Carousel.Caption style={{ lineHeight: '1.2', backgroundColor: 'rgba(0, 0, 0, 0.5)', color: 'white', padding: '10px' }}>
                <h3>Animal Digestion</h3>
                <p>Ruminant digestion emits methane via microbial fermentation in their stomachs.</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
            <div className="carousel-image-wrapper">
            <img
                className='carousel-image'
                src='https://images.unsplash.com/photo-1709532388333-acf472eae61a?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
                alt="First slide"
              />
              </div>
              <Carousel.Caption style={{ lineHeight: '1.2', backgroundColor: 'rgba(0, 0, 0, 0.5)', color: 'white', padding: '10px' }}>
                <h3>Fertilizers on Fields</h3>
                <p>
                Fertilizers release nitrous oxide and thus trapping heat in atmosphere.
                </p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={1500}>
            <div className="carousel-image-wrapper">
            <img
                className='carousel-image'
                src='https://images.unsplash.com/photo-1602770816979-f2b2c641ebd6?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
                alt="First slide"
              />
              </div>
              <Carousel.Caption style={{ lineHeight: '1.2', backgroundColor: 'rgba(0, 0, 0, 0.5)', color: 'white', padding: '10px' }}>
                <h3>Soil Management</h3>
                <p>Disturbing soil releases CO2 from decaying organic matter.</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={1500}>
            <div className="carousel-image-wrapper">
            <img
                className='carousel-image'
                src='https://images.unsplash.com/photo-1536167480021-ee03bbda5ab8?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
                alt="First slide"
              />
              </div>
              <Carousel.Caption style={{ lineHeight: '1.2', backgroundColor: 'rgba(0, 0, 0, 0.5)', color: 'white', padding: '10px' }}>
                <h3>Burning Crop Residues</h3>
                <p>Burning leftover plant harvest emits carbon dioxide and other pollutants.</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
            <div className="carousel-image-wrapper">
            <img
                className='carousel-image'
                src='https://images.unsplash.com/photo-1653643291896-07a6aae9b697?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
                alt="First slide"
              />
              </div>
              <Carousel.Caption style={{ lineHeight: '1.2', backgroundColor: 'rgba(0, 0, 0, 0.5)', color: 'white', padding: '10px' }}>
                <h3>Transportation of Goods</h3>
                <p>
                Transporting agricultural products burns fuels and emits CO2.
                </p>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
          </div>
          </div>



        <Card className="text-centercc">
      
      <Card.Body>
        <h2>Transform Your Farming Practices</h2>
        <p>
        Embark on your sustainable agriculture journey now. Connect with us to integrate eco-friendly practices and enhance your farm's resilience.
        </p>
        <button className="btn-orelse">Book a Demo</button>
      </Card.Body>
      
    </Card>

   
        </div>
    
  );
}

export default Customer3;