import images from "./images";

const Menu = [
    {
        text: "Home",
        link: "home",
    },
    {
        text: "About Us",
        link: "about-us",
    },
    {
        text: "Capabilities",
        link: "capabilities",
    },
    {
        text: "Solutions",
        link: "solutions",
    },

    {
        text: "Blogs",
        link: "blog",
    },
    {
        text: "FAQs",
        link: "faq",
    },
    {
        text: "Contact Us",
        link: "contact",
    }
];
const SolutionAvail = [
    {
        titleone: "Carbon ",
        titletwo: "Management",
        link: "/carbonmanagement",
        itemclass: "blight",
        imgURL: images.services01,
    },
    {
        titleone: "Sustainable",
        titletwo: "Supply Chain",
        link: "/sustainablesupplychain",
        itemclass: "bdark",
        imgURL: images.services02,
    },
    {
        titleone: "Sustainable",
        titletwo: "Green Finance",
        link: "/greenfinance",
        itemclass: "blight",
        imgURL: images.services03,
    },
    {
        titleone: "Sustainibility",
        titletwo: "Marketplace ",
        link: "https://marketplace.orelse.ai/",
        itemclass: "bdark",
        imgURL: images.services04,
    },
    {
        titleone: "Advisory and ",
        titletwo: "Consulting services",
        link: "/consultingservices",
        itemclass: "blight",
        imgURL: images.services05,
    },
    {
        titleone: " Climate modeling ",
        titletwo: "and impact",
        link: "/climateimpact",
        itemclass: "bdark",
        imgURL: images.services06,
    },
    {
        titleone: "Sustainable",
        titletwo: "Materials",
        link: "#",
        itemclass: "blight",
        imgURL: images.services01,
    },
    {
        titleone: "Become a sustainability",
        titletwo: "volunteer",
        link: "#",
        itemclass: "bdark",
        imgURL: images.services02,
    },
]
const Blog = [
    {
        text: "Unlocking the Path to Carbon Neutrality: Strategies for Project Developers",
        link: "#",
    },
    {
        text: "Sustainable Logistics Solutions: Navigating the Green Supply Chain",
        link: "#",
    },
    {
        text: "Financial Growth Meets Environmental Responsibility: Your Guide to Green Finance",
        link: "#",
    },
    
];

const Faq = [
    {
        title: "What services does orelse offer?",
        description: "orelse offers a range of innovative solutions for environmental excellence, including carbon emission management tailored for project developers, corporates, and individuals. Additionally, we provide sustainable logistics, green finance, a marketplace, advisory and consulting services, as well as climate modeling and impact analysis.",
    },
    {
        title: "How does orelse help businesses reduce their carbon footprint?",
        description: "orelse provides comprehensive tools and strategies for measuring, reporting, and mitigating carbon emissions, tailored to the specific needs and goals of each client.",
    },
    {
        title: "Can individuals also benefit from orelse's services?",
        description: "Absolutely! orelse offers solutions for individuals looking to reduce their personal carbon footprint, with tools and resources for making sustainable lifestyle choices.",
    },
    {
        title: "How can I get started with orelse's services?",
        description: "Simply reach out to our team through our website or contact information, and we'll be happy to discuss your needs and how orelse can help you achieve your environmental goals.",
    },

];


const Capabilities = [
    {
        title:"Collect emission data",
        info: "Ensure accurate and reliable data collection, empowering your organization to identify areas for improvement, mitigate environmental impact, and achieve long-term sustainability goals.",
        imgURL: images.c1,
    },
    {
        title: "Measure carbon footprint",
        info: "Understand your carbon footprint with our detailed analysis, pinpointing emissions hotspots and offering tailored, cost-effective strategies for reduction.",
        imgURL: images.c2,
    },
    {
        title: "Sustainability and ESG reporting",
        info: "Unlock transparency and showcase your commitment to sustainability .We provide detailed analysis, actionable insights, and customized strategies to align with your environmental, social, and governance goals.",
        imgURL: images.c3,
    },
    {
        title: "Set net zero target",
        info: "Forge a transformative path to carbon neutrality through net-zero target setting and implement robust monitoring mechanisms to track progress towards a sustainable future",
        imgURL: images.c4,
    },
    {
        title: "Emission Calculator",
        info: "Calculate your emissions with precision using our intuitive Emission Calculator, enabling informed decisions and proactive steps towards emissions reduction and sustainable practices.",
        imgURL: images.c5,
    },

];

const Aboutus = [
    {
        text:"At orelse, we are dedicated to revolutionizing the landscape of carbon emission management. With a firm belief in the power of innovation, we provide cutting-edge technology solutions designed to measure, report, and verify carbon emissions accurately. Through our comprehensive platform, businesses can gain invaluable insights into their environmental impact, enabling them to make informed decisions and take meaningful steps towards reducing their carbon footprint."
    },
    {
        text:"But we are more than just a tech company. We are a passionate team of environmental enthusiasts, committed to inspiring change and driving progress in the fight against climate change."
    },
    {
        text:"Join us on our journey towards a greener, cleaner future. Together, we can make a difference. Together, we can orelse."
    },
  
];

const TeamMembers = [
    {
        name:"Enter name",
        designation:"Designation",
        picture:images.tp1,
    },
    {
        name:"Enter name",
        designation:"Designation",
        picture:images.tp1,
    },
];

const Customers =[
    {
        Cust:'For Project Developers',
        Explain:'Tailored solutions for project developers, offering comprehensive assessments and strategies to minimize carbon emissions during construction and operation phases, ensuring sustainable project implementation.'
    },
    {
        Cust:'For Corporate',
        Explain:'Customized carbon emission management strategies for businesses, assisting in optimizing energy usage, achieving carbon neutrality goals, and enhancing corporate social responsibility initiatives.'
    },
    {
        Cust:'For Individuals',
        Explain:'Personalized solutions empowering individuals to reduce their carbon footprint through energy-efficient practices, renewable energy options, and carbon offset programs, fostering eco-friendly lifestyles.'
    },
    {
        Cust:'For Farmers',
        Explain:'Specialized support for farmers in adopting climate-smart agricultural practices, including soil carbon sequestration, methane emissions reduction, and participation in carbon credit programs, promoting sustainable farming methods.'
    },
];
export default { Menu, Blog, Faq, Capabilities, SolutionAvail,Aboutus ,TeamMembers,Customers};