import React, { useState } from 'react';
import './component.css';
import { images } from '../constants';

const LanguageSelectorPopup = ({ onSelect }) => {
  const [selectedLanguage, setSelectedLanguage] = useState('en');

  const handleLanguageSelect = (language) => {
    setSelectedLanguage(language);
    onSelect(language);
  };

  return (
    <div className="language-selector-popup">
      <div className="language-selector-content">
        <h2>Select Your Language</h2>
        <div className="language-dropdown">
          <img src={images.globe} alt="Language Icon" className="language-icon" />
          <select value={selectedLanguage} onChange={(e) => handleLanguageSelect(e.target.value)}>
          <option value="en">Select</option>
            <option value="en">English</option>
            <option value="mr">Marathi</option>
            <option value="hi">Hindi</option>
            <option value="kn">Kannada</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default LanguageSelectorPopup;
