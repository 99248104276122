import React, { useState } from 'react';
import { Collapse } from 'react-bootstrap';
import { Headings } from '../../components';
import { data } from '../../constants';
import { images } from '../../constants';
import './AboutUs.css';

import { useTranslation } from 'react-i18next';


const AboutUs = () => {
  const [open, setOpen] = useState(false);

  const { t } = useTranslation(); 

  return (
    <div className='container'>
    <div className="section-padding1" id="about-us">
    <Headings title={t("About Us")}/>
      <div className="about-us-box">
        <div className="about-us-content">
          
        {data.Aboutus.map(({ text}, index) => (
        <div>
        <p>{t(text)}</p>
        </div>
        ))}
      
        <h2 style={{ borderBottom: '2px solid black', paddingBottom: '5px' }}>{t("Our Vision")}</h2>
          <p>{t("Our goal is to establish a sustainable future whereby companies, individuals, and initiatives are inspired to reduce their carbon footprint and support environmental protection.")}</p>
          <h2 style={{ borderBottom: '2px solid black', paddingBottom: '5px' }}>{t("Our Mission")}</h2>
          <p>{t("At orelse, our mission is to redefine carbon emission management, driving towards a sustainable future by delivering accessible and effective solutions for environmental preservation.")}</p>
        </div>
        
        
      </div>


    </div>




    </div>

  );
};

export default AboutUs;
