import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { data } from '../../constants';
import { Headings } from '../../components';
import './FAQ.css';
import { useTranslation } from 'react-i18next';

const FAQ = () => {
  const { t } = useTranslation(); 
  return (
    <div className="section-faq" id="faq">
      <Headings title={t("FAQ")}  text={t("Take a look at our Frequently Asked Questions")} />

      <Accordion className="accordion-faq">
        {data.Faq.map((item, index) => (
          <Accordion.Item eventKey={index} key={index} className="accordion-faq-item">
            <Accordion.Header className="accordion-faq-header"><div><span>{index + 1 }</span> {t(item.title)}</div></Accordion.Header>
            <Accordion.Body className="accordion-faq-body">
              <p> {t(item.description)}  </p>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
  )
}

export default FAQ