import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import enTranslation from '../locales/en.json'; // Import English translations
import hiTranslation from '../locales/hi.json';
import mrTranslation from '../locales/mr.json';
import knTranslation from '../locales/kn.json';



i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: enTranslation }, // Add English translations
      hi: { translation: hiTranslation }, // Hindi translations
      mr: { translation: mrTranslation }, // Marathi translations
      kn: { translation: knTranslation }, // Kannada translations
    },
    fallbackLng: 'en', // Fallback language
    debug: true, // Enable debug mode
    interpolation: {
      escapeValue: false, // React already safely escapes strings
    },
  });

  export default i18n;