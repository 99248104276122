import React from 'react';
import './CTA.css';
import { images } from '../../constants';
import { useTranslation } from 'react-i18next';


const CTA = () => {
  const { t } = useTranslation(); 
  return (
    <div className='container'>



    <div className='ctasection'>
    <div className="section-padding">
      <div className="row cta justify-content-center">
        <div className="col-lg-8 col-12 text-center">
          <h3>{t("Let’s make things happen")}</h3>
          <p className="pt-2 pb-4">{t("Contact us today to discover how our carbon management solutions can empower your business to reduce its environmental footprint and thrive in a sustainable future.")}</p>
          <button className="btn-orelse">{t("Book a Demo")}</button>
        </div>
      </div>
    </div>
    </div>
    </div>
  );
  
}

export default CTA