import React, { useState }  from 'react';
import { images } from '../../constants';
import {IconScroll} from '../../components';
import './Home.css';
import { useTranslation } from 'react-i18next';


import Accordion from 'react-bootstrap/Accordion';

import { BsFillArrowUpRightCircleFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';

import Slider from "react-slick";

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  
  
};



const Home = () => {
  const { t } = useTranslation(); 

  const [activeTab, setActiveTab] = useState(1);

  const handleClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  return (
    

<div className='container-home'>
<section>
    <div className="slider-container-home">
    <Slider {...settings}>
      <div>


    <div className="homepage" id="home">
      <div className="row align-items-center">
        <div className="col-md-6 col-12">
          <h1 className="title">{t("Pioneering Sustainable Solutions for a Greener Tomorrow")} </h1>
          <p className="py-4">{t("Unlocking Innovation for a Sustainable Future, One Solution at a Time. Explore Our Range of Services Designed to Drive Environmental Progress, from Carbon Emission Management to Green Technology Solutions.")}</p>
          <div className="query-button">  <Link to="contact" className='query-link'> {t("Post Your Query")}</Link> </div>
        </div>
        <div className="col-md-6 col-12 mt-md-0 mt-4">
          <img className="img-fluid" src={images.hero} alt="design" />
        </div>

      </div>
      
      
      <IconScroll />
    </div>


    </div>
    <div>
    <div className="homepage">
      <div className="row align-items-center">
        <div className="col-md-6 col-12">
          <h1 className="title">{t("Transition to Net-Zero Emissions")} </h1>
          <p className="py-4">{t("Understand your carbon footprint, reduce your environmental impact and meet compliance standards with orelse ESG reporting & Climate Strategy Consulting.")}</p>
          <div className="query-button">  <Link to="contact" className='query-link'> {t("Post Your Query")}</Link> </div>
        </div>
        <div className="col-md-6 col-12 mt-md-0 mt-4">
          <img className="img-fluid" src={images.hero} alt="design" />
        </div>
        </div>

      </div>
        </div>
        <div>
        <div className="homepage">
      <div className="row align-items-center">
        <div className="col-md-6 col-12">
          <h1 className="title">{t("Track your carbon emissions")} </h1>
          <p className="py-4">{t("Find ways to cut carbon emissions with orelse. From energy-efficient upgrades to eco-friendly sustainable practices, we're here to help you make a real difference.")}</p>
          <div className="query-button">  <Link to="contact" className='query-link'> {t("Post Your Query")}</Link> </div>
        </div>
        <div className="col-md-6 col-12 mt-md-0 mt-4">
          <img className="img-fluid" src='https://plus.unsplash.com/premium_photo-1682308138964-f7db940e8c4b?q=80&w=1939&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D' alt="design" />
        </div>
        </div>
        </div>
        </div>
        
    </Slider>
    </div>
</section>

    

<section class="intro-text">
		<div class="container">
			<div class="row">
				<div class="col-md-12 col-lg-offset-1">

						<h1 >{t("Sustainability isn't a cost, it's an investment in a thriving future. Every step towards a lower carbon footprint counts. Let orelse guide your journey.")}</h1>					
            
            
        <a
          href="https://www.orelse.ai/contact"
          
          className="btn"
          style={{
            textDecoration: 'none',
            color: '#000',
            backgroundColor: '#fff',
            padding: '10px 20px',
            border: '2px solid #000',
            borderRadius: '5px',
            fontSize: '16px'
          }}
        >
          {t("Discover orelse")}
        </a>
       
    <hr class="h-line"/>
				</div>
			</div>
		</div>

    
	</section>



































  

    
        


</div>



  )
}

export default Home