import React from 'react';
import { data, images } from '../constants';
import '../pages/styles.css'

import Badge from 'react-bootstrap/Badge';
import Card from 'react-bootstrap/Card';


const Customer2 = () => {
  return (
    <div className="banner-pg">
    <div className="banner-pic">
      
      
      <div className="bannertitle">
        <h1>Empowering Individuals for Sustainable Living</h1>
      </div>
    </div>
      {/* First Section - Image on Left */}
      <div className="customer-container">
        <div className="image-container">
          {/* Insert your image here */}
          {/* Example: <img src="your-image-url.jpg" alt="Your Image" /> */}
          <img src='https://images.unsplash.com/photo-1614555281536-6755500b7494?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fGNhcmJvbiUyMGZvb3RwcmludHxlbnwwfHwwfHx8MA%3D%3D' alt="Image on Left" />
        </div>
        <div className="text-container1">
          <h1 className='custheading'>Calculate Your Carbon Footprint</h1>
          <ul>
          <Badge bg="badge">Carbon Footprint</Badge>
            <li>Utilize our carbon footprint calculator to assess your individual environmental impact.</li>
            <li>Input data on transportation, energy use, diet, and other lifestyle factors.</li>
            <li>Determine your carbon emissions and identify areas for improvement.</li>
          </ul>
        </div>
      </div>
      
      {/* Second Section - Image on Right */}
      <div className="customer-container">
        <div className="text-container1">
          <h1 className='custheading'>Sustainable Lifestyle Choices</h1>
          <ul>
          <Badge bg="badge">Eco Lifestyle</Badge>
            <li>Explore sustainable alternatives for everyday activities such as transportation, energy consumption, and food choices.</li>
            <li>Learn about eco-friendly practices that can help reduce your carbon footprint.</li>
            <li>Discover ways to minimize waste and support environmentally responsible products and services.</li>
          </ul>
        </div>
        <div className="image-container">
          {/* Insert your image here */}
          {/* Example: <img src="your-image-url.jpg" alt="Your Image" /> */}
          <img src='https://images.unsplash.com/photo-1635695604201-2b718204bccb?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjZ8fGNhcmJvbiUyMGZvb3RwcmludHxlbnwwfDB8MHx8fDA%3D' alt="Image on Right" />
        </div>
      </div>

      {/* Third Section - Image on Left */}
      <div className="customer-container">
        <div className="image-container">
          {/* Insert your image here */}
          {/* Example: <img src="your-image-url.jpg" alt="Your Image" /> */}
          <img src='https://images.unsplash.com/photo-1709450245503-4f903ffea3ff?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OTJ8fGNhcmJvbiUyMGZvb3RwcmludHxlbnwwfDB8MHx8fDA%3D' alt="Image on Left" />
        </div>
        <div className="text-container1">
          <h1 className='custheading'>Understanding Your Impact:</h1>
          <ul>
          <Badge bg="badge">Sustainable Practices</Badge>
            <li>Gain insights into your individual environmental impact through our comprehensive assessment tools.</li>
            <li>Analyze factors such as energy usage, transportation habits, and consumption patterns to understand your carbon footprint.</li>
            <li>Receive personalized feedback and recommendations for reducing your environmental impact.</li>
          </ul>
        </div>
      </div>

      
      <div className='list-card'>
      <div>
          <h1 style={{textAlign: 'center'}}>Practical Tips for Reducing Your Carbon Footprint</h1>
        </div>
        <ul className="cc2ul">
        <li className="cc2li">
        <img src='https://cdn-icons-png.flaticon.com/128/4729/4729446.png' alt="Icon 1" className="cc2icon" />
          <span className="cc2point">Incorporate eco-friendly habits into your daily life to minimize your carbon emissions.</span>
          
        </li>
       
        <li className="cc2li">
        <img src='https://cdn-icons-png.flaticon.com/128/1061/1061042.png' alt="Icon 3" className="cc2icon" />
          <span className="cc2point">Reduce energy consumption at home by turning off lights, unplugging electronics, and using energy-efficient appliances.</span>
          
        </li>
        <li className="cc2li">
        <img src='https://cdn-icons-png.flaticon.com/128/2975/2975943.png' alt="Icon 4" className="cc2icon" />
          <span className="cc2point">Make sustainable food choices by reducing meat consumption, food waste, and supporting local and organic products.</span>
          
        </li>
        <li className="cc2li">
        <img src='https://cdn-icons-png.flaticon.com/128/3779/3779288.png' alt="Icon 5" className="cc2icon" />
          <span className="cc2point">Practice waste reduction by recycling, composting, and minimizing single-use plastics.</span>
          
        </li>
      </ul>
      
        </div>

        <Card className="text-centercc">
      
      <Card.Body>
        <h2>Make Your Mark for Sustainability</h2>
        <p>
        Take action for a sustainable future. Join our movement today to play your part in preserving the planet for generations to come.
        </p>
        <button className="btn-orelse">Book a Demo</button>
      </Card.Body>
      
    </Card>

    </div>
    
    
  );
}

export default Customer2;
