import React from 'react';
import { images } from '../../constants';
import { IconScroll } from '../../components';
import './Resc.css';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const Resc = () => {
    const { t } = useTranslation(); 
   
    return (
        <div className="banner">
            <div className="banner-content">
                <div className="image-container">
                    <img src={images.banner} alt="Image" />
                </div>
                <div className="text-container">
                    <h1>{t("Enhance Your Understanding by Delving into Essential Resources")}</h1>
                    <p>{t("Explore resources crafted to enhance your understanding, equipping you with the knowledge needed to make informed choices.")}</p>
                    {/* Add onClick event to trigger handleExploreClick */}
                    <Link to="/new-page" className="btn bluebtn mt-2" style={{backgroundColor:'#fff',color:'#000',width:'40%'}}>
      {t("Find out more")}
    </Link>
                </div>
            </div>
        </div>
    );
};

export default Resc;
