import React from 'react'
import { Headings, IconScroll } from '../../components';
import { data } from '../../constants';
import './Blogs.css';
import { FiArrowUpRight } from 'react-icons/fi'
import { useTranslation } from 'react-i18next';


const Blogs = () => {

  const { t } = useTranslation(); 

  return (
    <div className='blogs-section'>
    <div className="section-padding" id="blog">

      <Headings title={t("Blogs")} text={t("Read about the Latest Insights with orelse's Informative Blogs")} />

        <div className="blogs">

          {data.Blog.map(({ text, link }, index) => (
            <div key="index" className="blogs-item">
              <p>{t(text)}</p>
              <a href={link} alt={t("Learn more")}>{t("Read more")} <FiArrowUpRight /></a>
            </div>
          ))}
        </div>
        
    <IconScroll />
   
      </div>
      </div>
  )
}

export default Blogs