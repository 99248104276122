import banner  from '../assets/banner.png';
import contact  from '../assets/contact.png';
import logo  from '../assets/logo.png';


import logofooter  from '../assets/logofooter.png';
import services01  from '../assets/services01.png';
import services02  from '../assets/services02.png';
import services03  from '../assets/services03.png';
import services04  from '../assets/services04.png';
import services05  from '../assets/services05.png';
import services06  from '../assets/services06.png';

import hero from '../assets/hero.png';

import c1 from '../assets/c1.svg';
import c2 from '../assets/c2.svg';
import c3 from '../assets/c3.svg';
import c4 from '../assets/c4.svg';
import c5 from '../assets/c5.svg';
import c6 from '../assets/c6.png';
import tp1 from '../assets/tp1.png';
import globe from '../assets/globe.png';


import googleplay from '../assets/googleplay.png'


import cust11 from '../assets/cust11.svg'
import cust22 from '../assets/cust22.svg'
import cust33 from '../assets/cust33.svg'
import cust44 from '../assets/cust44.svg'

import translate1 from '../assets/translate (1).png'
import arrow from '../assets/arrow.svg'
export default{
banner,
contact,
logo,

logofooter,
services01,
services02,
services03,
services04,
services05,
services06,

hero,

c1,
c2,
c3,
c4,
c5,
c6, 
tp1,

globe,


googleplay,


cust11,
cust22,
cust33,
cust44,

translate1,
arrow,
};