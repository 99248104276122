import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Headings } from '../../components';
import { data } from '../../constants';
import { images } from '../../constants';
import './Customer.css';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

const Customer = () => {
    const { t } = useTranslation();
    
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div>
            <Headings title={t("Eco-friendly Strategies for All")} />
            <section className="business-bg" >
                <div className="container-customer" >
                
                <Box sx={{ width: '100%'}}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider',width:'100%',display:'flex',justifyContent:'center'}}>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        textColor="black"
        
        sx={{
            '& .MuiTabs-indicator': {
                backgroundColor: 'black',
            },
            
        }}
        aria-label="secondary tabs example"
        >
        <Tab   icon={<img src={images.cust11}  className="cust-icon" />}label={t("For Project Developers")} {...a11yProps(0)}/>
        <Tab icon={<img src={images.cust22}  className="cust-icon" />}label={t("For Business")}  {...a11yProps(1)}/>
        <Tab  icon={<img src={images.cust33}  className="cust-icon" />} label={t("For Individuals")} {...a11yProps(2)}/>
        <Tab  icon={<img src={images.cust44}  className="cust-icon" />} label={t("For Farmers")} {...a11yProps(3)}/>
      </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        
      <div className="row table-content t-c-box">
                                <div className="col-md-6 text-center">
                                    <img src='https://images.unsplash.com/photo-1553877522-43269d4ea984?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'  className="p-t" />
                                </div>
                                <div className="col-md-6">
                                    <h4 className="h4">{t("Sell your Carbon credits")}</h4>
                                    <h2 className="heading-title">{t("Unlock Your Project's Potential with Carbon Management Solutions")}</h2>
                                    <p className="content">{t("Join the Movement Towards Sustainable Development")}</p>
                                    <Link to="/Customer0" className="btn bluebtn mt-2">{t("Find out more")}</Link>
                                </div>
                            </div>
                           
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
      <div className="row table-content t-c-box">
                                <div className="col-md-6 text-center">
                                    <img src='https://images.unsplash.com/photo-1518725414153-3d119aad7e76?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'   className="p-t" />
                                </div>
                                <div className="col-md-6">
                                    <h4 className="h4">{t("Maximize Impact, Minimize Footprint")}</h4>
                                    <h2 className="heading-title">{t("Empower Your Business for a Sustainable Future")}</h2>
                                    <p className="content">{t("Make a real contribution and go beyond CO2 Offset")}</p>
                                    <Link to="/Customer1" className="btn bluebtn mt-2">{t("Find out more")}</Link>
                                </div>
                            </div>
                    
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
      <div className="row table-content t-c-box">
                                <div className="col-md-6 text-center">
                                    <img src='https://images.unsplash.com/photo-1510524335095-4c8fff293074?q=80&w=2073&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'   className="p-t" />
                                </div>
                                <div className="col-md-6">
                                    <h4 className="h4">{t("From Awareness to Action")}</h4>
                                    <h2 className="heading-title">{t("Reduce Your Carbon Footprint, One Step at a Time")}</h2>
                                    <p className="content">{t("Take Control of Your Carbon Emissions")}</p>
                                    <Link to="/Customer2" className="btn bluebtn mt-2">{t("Find out more")}</Link>
                                </div>
                            </div>

      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
      <div className="row table-content t-c-box">
                                <div className="col-md-6 text-center">
                                    <img src='https://images.unsplash.com/photo-1496911402449-92a89a664775?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'   className="p-t" />
                                </div>
                                <div className="col-md-6">
                                    <h4 className="h4">{t("Sustainable Farming")}</h4>
                                    <h2 className="heading-title">{t("Empowering Farmers to Combat Climate Change Through Carbon Management")}</h2>
                                    <p className="content">{t("Maximizing Carbon Efficiency in Agriculture")}</p>
                                    <Link to="/Customer3" className="btn bluebtn mt-2">{t("Find out more")}</Link>
                                </div>
                            </div>

      </CustomTabPanel>
    </Box>
    
                </div>
            </section>
        </div>
    );
};

export default Customer;
