import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Home, AboutUs, Capabilities, Solutions, Blogs, FAQ, Contact, Footer, CTA, Resc, Customer,Process,ParallaxHome, Pricing } from './container';
import { CarbonManagement,SustainableLogistics,ConsultingServices,GreenFinance,ClimateImpact } from './container';
import { Menu } from './components';
import CarbonCalculator from './components/CarbonCalculator';
import { NewPage, Customer0, Customer1, Customer2, Customer3, DonatePage} from './pages';

import LanguageWrapper from './components/LanguageWrapper';
import withLanguagePopup from './hoc/withLanguagePopup';

import ScrollToTop from './components/ScrollToTop';
import ScrollBookmark from './components/ScrollBookmark';

import CookieConsent from 'react-cookie-consent';
import PrivacyPolicy from './pages/PrivacyPolicy';


const App = () => (
  
  

  <LanguageWrapper>
    <Router>
    <ScrollToTop/>
      <div className="container">
        <Menu /> {/* Menu is always present */}
        <Routes>
          <Route path="/" element={<MainContent />} />

          <Route path="/resources" element={<NewPage/>} />
          <Route path='/PrivacyPolicy' element={<PrivacyPolicy/>} /> 
          <Route path="/blogs" element={<NewPage />} />
          <Route path="/customerStories" element={<NewPage />} />
          <Route path="/Customer0" element={<Customer0 />} />
          <Route path="/Customer1" element={<Customer1 />} />
          <Route path="/Customer2" element={<Customer2 />} />
          <Route path="/Customer3" element={<Customer3 />} />


           <Route path="/home" element={<Navigate to="/" />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/capabilities" element={<Capabilities />} />
    
          <Route path="/solutions" element={<div style={{paddingTop:'40px'}}><Solutions /> <Customer/></div>} />
    
          <Route path="/blog" element={<div style={{paddingBottom:'20px'}}><Blogs /> <Resc/></div>} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/contact" element={<Contact />} />

          <Route path="/carbonmanagement" element={<CarbonManagement />} />
          <Route path="/sustainablesupplychain" element={<SustainableLogistics/>} />
          <Route path="/greenfinance" element={<GreenFinance />} />
          <Route path="/climateimpact" element={<ClimateImpact />} />
          <Route path="/consultingservices" element={<ConsultingServices />} />
          <Route path="/donateorelse" element={<DonatePage />} />
          <Route path="/carboncalculator" element={<CarbonCalculator />} />
        </Routes>
        <Footer />
        <CookieConsent
          location="bottom"
          buttonText="Accept"
          buttonStyle={{ backgroundColor: 'white' }}
          cookieName="myCookieConsent"
        >
          This website stores cookies on your computer. These cookies are used to collect information about how you interact with our website and allow us to remember you. We use this information in order to improve and customize your browsing experience and for analytics and metrics about our visitors both on this website and other media.
        </CookieConsent>
      </div>
    </Router>
  </LanguageWrapper>
);

const MainContent = () => (
  <div>
    <ScrollBookmark />
   
    <Home />
    
    <ParallaxHome/>
    <AboutUs />
    <Capabilities />
    <Customer />
    <Solutions />
    <Process/>
    <Resc />
    {/*<Pricing/>   Pricing component add later*/}
    <Blogs />
    <FAQ />
    <CTA />
    <Contact />
    
  </div>
);


export default withLanguagePopup(App);
