import React, { useState } from 'react';
import { data, images } from '../../constants';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Dropdown from 'react-bootstrap/Dropdown';
import i18n from '../i18n'; // Import i18n instance
import './Menu.css';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import { useTranslation } from 'react-i18next';


const Menu = () => {
    
    const [language, setLanguage] = useState('en');
    const location = useLocation();
    const { t } = useTranslation();

    const handleLanguageChange = (selectedLanguage) => {
        setLanguage(selectedLanguage);
        i18n.changeLanguage(selectedLanguage); // Change the language using i18n
    };


    return (
        <Navbar collapseOnSelect expand="lg" bg="white" variant="white" >
            <Container>
                
                <Navbar.Brand href="/home" > <img src={images.logo} alt="logo" style={{width:'62%'}}/></Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end"  >
                    <Nav>
                        {data.Menu.map((item, index) => (
                            // Use a ternary operator to conditionally render either a ScrollLink or a Link
                            location.pathname === '/' ? (
                                <ScrollLink key={index} to={item.link} smooth={true} duration={100} offset={-100} className="nav-link">{t(item.text)} </ScrollLink>
                            ) : (
                                <Link key={index} to={item.link} className="nav-link">{t(item.text)}</Link>
                            )
                        ))}
                    </Nav>
                  <div className="giverly-button">
                    <Link to="https://marketplace.orelse.ai/" className="giverly-link">Marketplace</Link>
                    
                    </div>
                    <Dropdown className="language-dropdown" drop="down">
  <Dropdown.Toggle variant="white" id="dropdown-basic" style={{ position: 'relative', zIndex: '9999' }}>
    <img src={images.globe} alt="Translation Icon" style={{ width: '30px', height: '30px' }} />
  </Dropdown.Toggle>

  <Dropdown.Menu style={{ position: 'absolute', right: '0', left: 'auto' }}>
    <Dropdown.Item onClick={() => handleLanguageChange('en')}>English</Dropdown.Item>
    <Dropdown.Item onClick={() => handleLanguageChange('hi')}>Hindi</Dropdown.Item>
    <Dropdown.Item onClick={() => handleLanguageChange('mr')}>Marathi</Dropdown.Item>
    <Dropdown.Item onClick={() => handleLanguageChange('kn')}>Kannada</Dropdown.Item>
  </Dropdown.Menu>
</Dropdown>


                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default Menu;

