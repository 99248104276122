import React from 'react'
import { Headings } from '../../components';
import { data,images } from '../../constants'; 

import './Capabilities.css';
import { useTranslation } from 'react-i18next';

const Capabilities = () => {
  const { t } = useTranslation(); 
  return (
    
    <div className="capabilities-solve" id="capabilities">
    <Headings title={t("Capabilities")} text={t("Discover the Diverse Capabilities of orelse")} />   
    <div className="container">
    <div class="b-p-flex">
                    <div class="b-p-table">
                    <div class="b-p-box">
                        <img src={images.c1} class="b-s-img"/>
                    </div>
                    <div class="b-p-box">
                        <h4 class="sub-heading">{t("Collect emission data")}</h4>
                        <p class="content">
                        {t("Ensure accurate and reliable data collection, empowering your organization to identify areas for improvement, mitigate environmental impact, and achieve long-term sustainability goals.")}
                        </p>
                    </div>
                </div>
            
            
                                        
                    <div class="b-p-table">
                    <div class="b-p-box">
                        <img src={images.c2} class="b-s-img"/>
                    </div>
                    <div class="b-p-box">
                        <h4 class="sub-heading">{t("Measure carbon footprint")}</h4>
                        <p class="content">
                        {t("Understand your carbon footprint with our detailed analysis, pinpointing emissions hotspots and offering tailored, cost-effective strategies for reduction.")}
                        </p>
                    </div>
                </div>
                </div>
            
            
                                        
                    <div class="b-p-flex">
                    <div class="b-p-table">
                    <div class="b-p-box">
                        <img src={images.c3} class="b-s-img"/>
                    </div>
                    <div class="b-p-box">
                        <h4 class="sub-heading">{t("Sustainability and ESG reporting")}</h4>
                        <p class="content">
                        {t("Unlock transparency and showcase your commitment to sustainability .We provide detailed analysis, actionable insights, and customized strategies to align with your environmental, social, and governance goals.")}
                        </p>
                    </div>
                </div>
            
            
                                        
                    <div class="b-p-table">
                    <div class="b-p-box">
                        <img src={images.c4} class="b-s-img"/>
                    </div>
                    <div class="b-p-box">
                        <h4 class="sub-heading">{t("Set net zero target")}</h4>
                        <p class="content">
                        {t("Forge a transformative path to carbon neutrality through net-zero target setting and implement robust monitoring mechanisms to track progress towards a sustainable future")}
                        </p>
                    </div>
                </div>

                
                    
                
            </div>
           


            <div class="b-p-flex">
                    <div class="b-p-table">
                    <div class="b-p-box">
                        <img src={images.c5} class="b-s-img"/>
                    </div>
                    <div class="b-p-box">
                        <h4 class="sub-heading">{t("Emission Calculator")}</h4>
                        <p class="content">
                        {t("Calculate your emissions with precision using our intuitive Emission Calculator, enabling informed decisions and proactive steps towards emissions reduction and sustainable practices.")}
                        </p>
                    </div>
                </div>
            
            
                                        
                    <div class="b-p-table">
                    <div class="b-p-box">
                        <img src={images.c6} class="b-s-img"/>
                    </div>
                    <div class="b-p-box">
                        <h4 class="sub-heading">{t("Environmental Impact Analysis (EIA)")}</h4>
                        <p class="content">
                        {t("Our comprehensive Environmental Impact Analysis (EIA) evaluates climate implications, enabling informed decisions and proactive strategies for climate action and resilience.")}
                        </p>
                    </div>
                </div>
                </div>
            


        </div>
        </div>
);
};
export default Capabilities
