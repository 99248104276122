import React, { useState } from 'react';
import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container'



const CarbonCalculator = () => {
  const [familyMembers, setFamilyMembers] = useState(0);
  const [petrolLitres, setPetrolLitres] = useState(0);
  const [dieselLitres, setDieselLitres] = useState(0);
  const [autoLPGLitres, setAutoLPGLitres] = useState(0);
  const [taxiKm, setTaxiKm] = useState(0);
  const [localBusKm, setLocalBusKm] = useState(0);
  const [autorickshawKm, setAutorickshawKm] = useState(0);
  const [localTrainKm, setLocalTrainKm] = useState(0);
  const [lpgCylinders, setLpgCylinders] = useState(0);
  const [cngUsed, setCngUsed] = useState(0);
  const [electricityUsed, setElectricityUsed] = useState(0);

  // Define a function to calculate CO2 emissions
  const calculateCO2Emissions = () => {
    // Define constants for CO2 emissions per unit
    const petrolCO2PerLitre = 2.31; 
    const dieselCO2PerLitre = 2.68; 
    const autoLPGCO2PerKg = 3.06; 
    const taxiCO2PerKm = 0.31; 
    const localBusCO2PerKm = 0.05; 
    const autorickshawCO2PerKm = 0.05; 
    const localTrainCO2PerKm = 0.1; 
    const lpgCO2PerCylinder = 42.5; 
    const cngCO2PerM3 = 1.82; 
    const electricityCO2PerKWh = 0.75; 

    // Perform calculations
    const petrolCO2 = petrolLitres * petrolCO2PerLitre;
    const dieselCO2 = dieselLitres * dieselCO2PerLitre;
    const autoLPGCO2 = autoLPGLitres * autoLPGCO2PerKg;
    const taxiCO2 = taxiKm * taxiCO2PerKm;
    const localBusCO2 = localBusKm * localBusCO2PerKm;
    const autorickshawCO2 = autorickshawKm * autorickshawCO2PerKm;
    const localTrainCO2 = localTrainKm * localTrainCO2PerKm;
    const lpgCO2 = lpgCylinders * lpgCO2PerCylinder;
    const cngCO2 = cngUsed * cngCO2PerM3;
    const electricityCO2 = electricityUsed * electricityCO2PerKWh;

    // Return the total CO2 emissions
    return (
     (petrolCO2 +
      dieselCO2 +
      autoLPGCO2 +
      taxiCO2 +
      localBusCO2 +
      autorickshawCO2 +
      localTrainCO2 +
      lpgCO2 +
      cngCO2 +
      electricityCO2)/familyMembers
    );
  };

  // Render JSX
  return (
    <div>
      <Container>
    <form>
      <Table bordered hover size="sm" responsive="sm"  className="w-full">
        <thead>
          <tr>
            <th>Activity/Fuel Use</th>
            <th>Quantity</th>
            <th>CO<sub>2</sub>e (KG)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              Enter Total Family Members (*Include self):
            </td>
            <td>
              <input
                min="1"
                className="w-full bg-white border border-bordcol rounded px-2 py-2 form-control-sm"
                placeholder=""
                type="number"
                value={familyMembers}
                onChange={(e) => setFamilyMembers(e.target.value)}
              />
            </td>
            <td></td>
          </tr>
          <tr>
            <td>Petrol Litres:</td>
            <td>
              <input
                min="0"
                className="w-full bg-white border border-bordcol rounded px-2 py-2 form-control-sm"
                placeholder=""
                type="number"
                value={petrolLitres}
                onChange={(e) => setPetrolLitres(e.target.value)}
              />
            </td>
            <td>{(petrolLitres * 2.31).toFixed(2)} KG</td>
          </tr>
          <tr>
            <td>Diesel Litres:</td>
            <td>
              <input
                min="0"
                className="w-full bg-white border border-bordcol rounded px-2 py-2 form-control-sm"
                placeholder=""
                type="number"
                value={dieselLitres}
                onChange={(e) => setDieselLitres(e.target.value)}
              />
            </td>
            <td>{(dieselLitres * 2.68).toFixed(2)} KG</td>
          </tr>
          {/* Add similar rows for other fuel types */}
          <tr>
            <td>Auto LPG (kg):</td>
            <td>
              <input
                min="0"
                className="w-full bg-white border border-bordcol rounded px-2 py-2 form-control-sm"
                placeholder=""
                type="number"
                value={autoLPGLitres}
                onChange={(e) => setAutoLPGLitres(e.target.value)}
              />
            </td>
            <td>{(autoLPGLitres * 3.06).toFixed(2)} KG</td>
          </tr>

          <tr>
            <td>Taxis (km):</td>
            <td>
              <input
                min="0"
                className="w-full bg-white border border-bordcol rounded px-2 py-2 form-control-sm"
                placeholder=""
                type="number"
                value={taxiKm}
                onChange={(e) => setTaxiKm(e.target.value)}
              />
            </td>
            <td>{(taxiKm * 0.31).toFixed(2)} KG</td>
          </tr>

          <tr>
            <td>Local bus (Best/Company bus) (km):</td>
            <td>
              <input
                min="0"
                className="w-full bg-white border border-bordcol rounded px-2 py-2 form-control-sm"
                placeholder=""
                type="number"
                value={localBusKm}
                onChange={(e) => setLocalBusKm(e.target.value)}
              />
            </td>
            <td>{(localBusKm * 0.05).toFixed(2)} KG</td>
          </tr>

          <tr>
            <td>Autorickshaw (km):</td>
            <td>
              <input
                min="0"
                className="w-full bg-white border border-bordcol rounded px-2 py-2 form-control-sm"
                placeholder=""
                type="number"
                value={autorickshawKm}
                onChange={(e) => setAutorickshawKm(e.target.value)}
              />
            </td>
            <td>{(autorickshawKm * 0.05).toFixed(2)} KG</td>
          </tr>

          <tr>
            <td>Local train (km):</td>
            <td>
              <input
                min="0"
                className="w-full bg-white border border-bordcol rounded px-2 py-2 form-control-sm"
                placeholder=""
                type="number"
                value={localTrainKm}
                onChange={(e) => setLocalTrainKm(e.target.value)}
              />
            </td>
            <td>{(localTrainKm * 0.1).toFixed(2)} KG</td>
          </tr>
          <td>Domestic Use</td>
          <tr>
            <td>Number of LPG Cylinders Used for cooking:</td>
            <td>
              <input
                min="0"
                className="w-full bg-white border border-bordcol rounded px-2 py-2 form-control-sm"
                placeholder=""
                type="number"
                value={lpgCylinders}
                onChange={(e) => setLpgCylinders(e.target.value)}
              />
            </td>
            <td>{(lpgCylinders * 42.5).toFixed(2)} KG</td>
          </tr>

          <tr>
            <td>Amount of CNG used at home (m3):</td>
            <td>
              <input
                min="0"
                className="w-full bg-white border border-bordcol rounded px-2 py-2 form-control-sm"
                placeholder=""
                type="number"
                value={cngUsed}
                onChange={(e) => setCngUsed(e.target.value)}
              />
            </td>
            <td>{(cngUsed * 1.82).toFixed(2)} KG</td>
          </tr>

          <tr>
            <td>Electricity used in a month (kWh):</td>
            <td>
              <input
                min="0"
                className="w-full bg-white border border-bordcol rounded px-2 py-2 form-control-sm"
                placeholder=""
                type="number"
                value={electricityUsed}
                onChange={(e) => setElectricityUsed(e.target.value)}
              />
            </td>
            <td>{(electricityUsed * 0.75).toFixed(2)} KG</td>
          </tr>



        </tbody>
        </Table>
      <div><h4>Your Total CO2 Emissions  (kg CO2e/Month): {calculateCO2Emissions()} KG</h4></div>
      <div><h4>Your average annual carbon footprint (Tonnes of CO2e): {((calculateCO2Emissions()/1000)*12).toFixed(3)} KG</h4></div>
    </form>
    </Container>
    </div>
  );
};

export default CarbonCalculator